import { useMemo, useState, useEffect } from "react"
import { ethers } from "ethers"
import { isEmpty } from "lodash"
import store2 from "store2"
import { getAccountControl, HYPER_PERSIST_TRADING_CONNECTION } from '../utils/legacy'
import { useChainId } from "../utils/chains";
import useSubAccountStore from "../store/useSubAccountStore";
import { useShallow } from 'zustand/react/shallow'
import useWallet from "../lib/wallets/useWallet";

let isFetching = false; 

export default function useHyperConnection() {
    const [sessionConnect, setSessionConnect] = useState({});
    const [localConnect, setLocalConnect] = useState({});
    const [userAddress, setUserAddress] = useState({});
    const [localTradingConnection, setLocalTradingConnection] = useState(false);
    const { account } = useWallet();
    const { parentAccount:storeParentAccount, isSubConnected:storeIsSubConnected, setSubAccount } = useSubAccountStore(
        useShallow((state) => ({ parentAccount: state.parentAccount, isSubConnected: state.isSubConnected, setSubAccount: state.setSubAccount })),
    )
    const { chainId } = useChainId()
    const HYPER_USER_CONNECTION = `deriw_agent_${account}`
    
    useEffect(() => {
        async function fetchParentAccount() {
            if (isFetching) return; // 如果正在请求，则直接返回
            isFetching = true; // 设置请求锁
            if (storeIsSubConnected && storeParentAccount === account) {
                isFetching = false; // 请求结束，释放锁
                return;
            }

            let parentAccount = null;
            if (account && localConnect?.agentAddress && localTradingConnection) {
                parentAccount = await getAccountControl(chainId, localConnect.agentAddress);

            } else if (account && sessionConnect?.agentAddress) {
                parentAccount = await getAccountControl(chainId, sessionConnect.agentAddress);
            }

            if (parentAccount) {
                setUserAddress(parentAccount);
                if (storeParentAccount !== parentAccount && account === parentAccount) {
                    setSubAccount({ parentAccount, isSubConnected: true });
                }
            }
            isFetching = false; // 请求结束，释放锁
        }
        fetchParentAccount();
    }, [account, chainId, sessionConnect, localConnect, localTradingConnection, storeParentAccount, storeIsSubConnected, isFetching]);

    useEffect(() => {
        // 初始化时从 SessionStorage 获取用户代理数据
        const storedSessionConnect = store2.session.get(HYPER_USER_CONNECTION);
        if (storedSessionConnect) setSessionConnect(storedSessionConnect);
        // 初始化时从 LocalStorage 获取用户代理数据
        const storedLocalConnect = store2.get(HYPER_USER_CONNECTION);
        if (storedLocalConnect) setLocalConnect(storedLocalConnect);
        // 初始化时从 LocalStorage 获取是否授权
        const storedLocalTradingConnection = store2.get(HYPER_PERSIST_TRADING_CONNECTION);
        if(storedLocalTradingConnection) setLocalTradingConnection(true)

        // 添加 storage 事件监听器
        const handleStorageChange = (e) => {
            if (e.key === HYPER_USER_CONNECTION) {
                // 根据存储类型更新状态
                if (e.storageArea === sessionStorage) {
                    const newSessionConnect = store2.session.get(HYPER_USER_CONNECTION);
                    setSessionConnect(newSessionConnect || {});
                } else if (e.storageArea === localStorage) {
                    const newLocalConnect = store2.get(HYPER_USER_CONNECTION);
                    setLocalConnect(newLocalConnect || {});
                }
            }
        };

        window.addEventListener('storage', handleStorageChange);

        // // 清理函数
        // return () => {
        //     window.removeEventListener('storage',  () => {
		// 		void 0;
		// 	});
        // };
    }, [HYPER_USER_CONNECTION]);

    /**
     * @description 检查用户连接状态和钱包信息
     * @returns {Array} [isSessionConnect, isLocalConnect, isConnection, connectionWallet]
     * - isSessionConnect: 会话存储连接状态
     * - isLocalConnect: 本地存储连接状态  
     * - isConnection: 总体连接状态
     * - connectionWallet: 当前连接的钱包信息
     */
    const [hasSessionConnect, isSessionConnect, isLocalConnect, connectedSubWallet] = useMemo(() => {
        const initialState = {
            hasSessionConnect: false,
            isSessionConnect: false,
            isLocalConnect: false,
            connectedSubWallet: {}
        };
        if (account && !isEmpty(sessionConnect)) {
            initialState.hasSessionConnect = true;
            initialState.connectedSubWallet = sessionConnect;
        }
        // 检查会话存储连接
        if (account && !isEmpty(sessionConnect) && account === userAddress) {
            initialState.isSessionConnect = true;
            initialState.connectedSubWallet = sessionConnect;
        }

        // 检查本地存储连接
        if (account && !isEmpty(localConnect) && account === userAddress && localTradingConnection) {
            initialState.isSessionConnect = false;
            initialState.isLocalConnect = true;
            initialState.connectedSubWallet = localConnect;
        }

        return [
            initialState.hasSessionConnect,
            initialState.isSessionConnect,
            initialState.isLocalConnect, 
            initialState.connectedSubWallet
        ];
    }, [account, sessionConnect, localConnect, userAddress, localTradingConnection]);

    const setHyperStorage = (key = HYPER_USER_CONNECTION, value, type = 'sessionStorage') => {
        if(type === 'sessionStorage') {
            store2.session.set(key, value);
        } else {
            store2.set(key, value);
        }
    }

    return { 
        setHyperStorage,
        hasSessionConnect,
        isSessionConnect,
        isLocalConnect,
        connectedSubWallet,
        localTradingConnection,
        sessionConnect,
    }
}    
