import { getDefaultConfig } from "@rainbow-me/rainbowkit";
import {
  metaMaskWallet,
  okxWallet,
  walletConnectWallet,
  coinbaseWallet,
  injectedWallet,
  bitgetWallet,
  rabbyWallet,
  safeWallet,
  trustWallet,
} from "@rainbow-me/rainbowkit/wallets";
import config from "../../config";
import once from "lodash/once";
import { http } from "viem";
import { arbitrum, arbitrumSepolia } from "viem/chains";

import binanceWallet from "./connecters/binanceW3W/binanceWallet";
import keplrWallet from "./connecters/keplrWallet/keplrWallet";


const WALLET_CONNECT_PROJECT_ID = "47bab16dc1a8b52e42e3c7c81bfe197e";
const APP_NAME = "Deriw";

const popularWalletList = [
  {
    // Group name with standard name is localized by rainbow kit
    groupName: "Popular",
    wallets: [
      metaMaskWallet,
      okxWallet,
      ...(window.keplr ? [] : [keplrWallet]),
      walletConnectWallet,
      coinbaseWallet,
      // This wallet will automatically hide itself from the list when the fallback is not necessary or if there is no injected wallet available.
      // injectedWallet,
      // The Safe option will only appear in the Safe Wallet browser environment.
      safeWallet,
    ],
  },
];

const othersWalletList = [
  {
    groupName: "Others",
    // wallets: [bitgetWallet, binanceWallet, trustWallet, rabbyWallet],
    wallets: [bitgetWallet, trustWallet, rabbyWallet],
  },
];

export const getRainbowKitConfig = once(() =>
  getDefaultConfig({
    appName: APP_NAME,
    projectId: WALLET_CONNECT_PROJECT_ID,
    chains: config.isDevelopment() || config.isPre() ? [arbitrumSepolia] :[arbitrum],
    transports: {
      [arbitrum.id]: http(),
      [arbitrumSepolia.id]: http(),
    },
    wallets: [...popularWalletList, ...othersWalletList],
  })
);
