import { BigNumber, ethers } from "ethers";
import { decimalBit } from "../utils";
import { isEmpty } from "lodash";
export function bigNumberify(n) {
  try {
    return BigNumber.from(n);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("bigNumberify error", e);
    return undefined;
  }
}

export function expandDecimals(n, decimals) {
  // @ts-ignore
  return bigNumberify(n).mul(bigNumberify(10).pow(decimals));
}

export const trimZeroDecimals = (amount) => {
  if (parseFloat(amount) === parseInt(amount)) {
    return amount.toString().split(".")[0]
    // return parseInt(amount).toString();
  }
  return amount;
};

export const limitDecimals = (amount, maxDecimals) => {
  let amountStr = amount.toString();
  if (maxDecimals === undefined) {
    return amountStr;
  }
  if (maxDecimals === 0) {
    return amountStr.split(".")[0];
  }
  const dotIndex = amountStr.indexOf(".");
  if (dotIndex !== -1) {
    let decimals = amountStr.length - dotIndex - 1;
    if (decimals > maxDecimals) {
      amountStr = amountStr.substr(0, amountStr.length - (decimals - maxDecimals));
    }
  }
  return amountStr;
};

export const padDecimals = (amount, minDecimals) => {
  let amountStr = amount.toString();
  const dotIndex = amountStr.indexOf(".");
  if (dotIndex !== -1) {
    const decimals = amountStr.length - dotIndex - 1;
    if (decimals < minDecimals) {
      amountStr = amountStr.padEnd(amountStr.length + (minDecimals - decimals), "0");
    }
  } else {
    amountStr = amountStr + ".0000";
  }
  return amountStr;
};

export const formatAmount = (
  amount,
  tokenDecimals,
  displayDecimals,
  useCommas,
  defaultValue
) => {
  if (!defaultValue) {
    defaultValue = "...";
  }
  if (amount === undefined || amount.toString().length === 0) {
    return defaultValue;
  }
  if (displayDecimals === undefined) {
    displayDecimals = 4;
  }
  let amountStr = ethers.utils.formatUnits(amount, tokenDecimals);
  amountStr = limitDecimals(amountStr, displayDecimals + 4);
  amountStr = decimalBit(parseFloat(amountStr), displayDecimals)
  // amountStr = parseFloat(amountStr).toFixed(displayDecimals)
  if (displayDecimals !== 0) {
    amountStr = padDecimals(amountStr, displayDecimals);
  }
  if (useCommas) {
    return numberWithCommas(amountStr);
  }
  return amountStr;
};
export const formatAmountFixed = (
  amount,
  tokenDecimals,
  displayDecimals,
  useCommas,
  defaultValue
) => {
  if (!defaultValue) {
    defaultValue = "...";
  }
  if (amount === undefined || amount.toString().length === 0) {
    return defaultValue;
  }
  if (displayDecimals === undefined) {
    displayDecimals = 4;
  }
  let amountStr = ethers.utils.formatUnits(amount, tokenDecimals);
  amountStr = limitDecimals(amountStr, displayDecimals + 4);
  amountStr = parseFloat(amountStr).toFixed(displayDecimals)
  if (displayDecimals !== 0) {
    amountStr = padDecimals(amountStr, displayDecimals);
  }
  if (useCommas) {
    return numberWithCommas(amountStr);
  }
  return amountStr;
};

export const formatKeyAmount = (
  map,
  key,
  tokenDecimals,
  displayDecimals,
  useCommas
) => {
  if (!map || !map[key]) {
    return "...";
  }

  return formatAmount(map[key], tokenDecimals, displayDecimals, useCommas);
};

export const formatArrayAmount = (
  arr,
  index,
  tokenDecimals,
  displayDecimals,
  useCommas
) => {
  if (!arr || !arr[index]) {
    return "...";
  }

  return formatAmount(arr[index], tokenDecimals, displayDecimals, useCommas);
};

export const formatAmountFree = (amount, tokenDecimals, displayDecimals) => {
  if (!amount) {
    return "...";
  }
  let amountStr = ethers.utils.formatUnits(amount, tokenDecimals);
  amountStr = limitDecimals(amountStr, displayDecimals);
  return trimZeroDecimals(amountStr);
};

export const parseValue = (value, tokenDecimals) => {
  try {
    // 检查是否为空
    if(typeof value === 'string' && isEmpty(value)){
      return bigNumberify(0);
    }
    
    // 处理科学计数法
    if (typeof value === 'number' || (typeof value === 'string' && value.includes('e'))) {
      // 将科学计数法转换为普通数字字符串
      value = Number(value).toFixed(tokenDecimals);
    }
    
    // 解析为数字并验证
    const pValue = parseFloat(value);
    if (isNaN(pValue)) {
      return bigNumberify(0);
    }
    
    // 限制小数位数
    value = limitDecimals(value, tokenDecimals);
    
    // 转换为 BigNumber
    const amount = ethers.utils.parseUnits(value, tokenDecimals);
    return bigNumberify(amount);
  } catch (error) {
    console.error("Error in parseValue:", error, "Input:", value);
    return bigNumberify(0); // 出错时返回0
  }
};

export function numberWithCommas(x, isZero) {
  if (!x) {
    if (isZero) {
      return 0
    }
    return "...";
  }

  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export function roundToTwoDecimals(n) {
  return Math.round(n * 100) / 100;
}

export function randomNumber() {
  return parseInt(Math.random() * 100000000) + 100000000
}


 /**
   * 将货币字符串转换为纯数字
   * @param {string} currencyStr - 货币字符串 如 "$84,706.04"
   * @returns {number} 转换后的数字 如 84706.04
   */
 export const currencyToNumber = (currencyStr) => {
  // 移除非数字字符（保留小数点、负号）
  const numStr = currencyStr.replace(/[^\d.-]/g, '');

  // 处理千分位逗号（如果存在）
  const cleanStr = numStr.replace(/,/g, '');

  // 转换为数字
  const number = parseFloat(cleanStr);

  // 处理无效转换
  if (isNaN(number)) {
    console.warn(`无法转换的字符串: ${currencyStr}`);
    return 0; // 或根据需求返回 null/抛出错误
  }

  return number;
};