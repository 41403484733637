import "./App.scss";
import React, { Suspense, useEffect, useMemo } from "react";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import Nav from "../src/components/Nav";
import BrowserNotification from "../src/components/BrowserNotification";
import Footer from "../src/components/Footer";
import setupLocatorUI from "@locator/runtime";
import { useMedia } from "react-use";
import { useSocket } from "./socket";
import store from 'store2';
import i18next from "i18next";
import useWalletDeriw from "./utils/wallets/useWalletDeriw";
import { useLoadTokens } from "./config/token";
import { isSupportedChain } from "./config/chains";
import { Warning, ShowToast } from "./components/Toast";
import httpApi from "./api";
import { useChainId } from "./utils/chains";
import { createLog } from "./utils/eventTracking";
import Loading from './components/Lazyload/Loading';  // 你的加载组件
import { lazyLoadWithLoading } from './components/Lazyload';
import {INVALID_NETWORK_TOAST_ID, getInvalidNetworkErrorMessage} from "./lib/contracts/transactionErrors" 
import { getRainbowKitConfig } from "./lib/wallets/rainbowKitConfig";
import { watchAccount } from "@wagmi/core";
import { useAccount } from "wagmi";
import VConsole from 'vconsole';

const Home = lazyLoadWithLoading(() => import("./pages/home"), Loading);
const TransactionParams = lazyLoadWithLoading(() => import("./pages/transaction"), Loading);
const TransactionNone = lazyLoadWithLoading(() => import("./pages/transactionNone"), Loading);
const Earn = lazyLoadWithLoading(() => import("./pages/earn"), Loading);
const MemeEarn = lazyLoadWithLoading(() => import("./pages/memeEarn"), Loading);
const Ecology = lazyLoadWithLoading(() => import("./pages/ecology"), Loading);
const RecommendShare = lazyLoadWithLoading(() => import("./pages/recommend"), Loading);
const RecommendSetting = lazyLoadWithLoading(() => import("./pages/recommendSetting"), Loading);
const AboutFundPool = lazyLoadWithLoading(() => import("./pages/aboutFundPool"), Loading);
const EarnMy = lazyLoadWithLoading(() => import("./pages/earnMy"), Loading);
const MeMeEarnMy = lazyLoadWithLoading(() => import("./pages/memeEarnMy"), Loading);
const CreateChallenge = lazyLoadWithLoading(() => import("./pages/createChallenge"), Loading);
const CreateFund = lazyLoadWithLoading(() => import("./pages/createFund"), Loading);
const ChallengeDetail = lazyLoadWithLoading(() => import("./pages/challengeDetail"), Loading);
const Challenge = lazyLoadWithLoading(() => import("./pages/challenge"), Loading);
const CrossChain = lazyLoadWithLoading(() => import("./pages/crossChain"), Loading);
const TradingCompetition = lazyLoadWithLoading(() => import("./pages/tradingCompetition"), Loading);
const Dashboard = lazyLoadWithLoading(() => import("./pages/dashboard"), Loading);
const BountyHunter = lazyLoadWithLoading(() => import("./pages/bountyHunter"), Loading);
const BugCompetition = lazyLoadWithLoading(() => import("./pages/bugCompetition"), Loading);
const Avtivity = lazyLoadWithLoading(() => import("./pages/avtivity"), Loading)
const Supernova = lazyLoadWithLoading(() => import("./pages/supernova"),Loading)
const Legends = lazyLoadWithLoading(() => import("./pages/legends"),Loading)
const Airdrop = lazyLoadWithLoading(() => import("./pages/airdrop"),Loading)
const CoinInfo = lazyLoadWithLoading(() => import("./pages/coinInfo"),Loading)

const App = () => {
  const isMobile = useMedia("(max-width: 700px)");
  const { tokens } = useLoadTokens()
  const { account, active } = useWalletDeriw()
  const { chainId } = useChainId()
  const { chainId:currentChain } = useAccount()
  // 页面加载完 调用埋点方法
  useEffect(() => {
    if (account) {
      createLog(account, 1)
      httpApi.operationsAvtitityconnectWalletLog({address: account})
      // httpApi.connectWalletLog({address: account})
      httpApi.bountyHunterLog({address: account})
    }
  }, [account])

  const isNetWorkError = useMemo(()=>{
    if(active && account && currentChain){
      return !isSupportedChain(currentChain)
    }
    return false 
  },[currentChain, active, account])

  useEffect(() => {
    if(isNetWorkError && active){
      const failMsg = getInvalidNetworkErrorMessage(chainId)
      ShowToast(INVALID_NETWORK_TOAST_ID, i18next.t("提示"), Warning, [failMsg], { forever: true })
    }
  },[isNetWorkError, active])

  useEffect(() => {
    const unsubscribe = watchAccount(getRainbowKitConfig(), {
      onChange: (newAccount) => {
        if (!newAccount.chainId) return;
        if (!isSupportedChain(newAccount.chainId)) return;
        if(newAccount?.isConnected && account && newAccount.address.toLowerCase() !== account?.toLowerCase()){
            window.location.reload();
        }
        // setDisplayedChainId(account.chainId);
        // localStorage.setItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY, account.chainId.toString());
      },
    });

    return () => {
      unsubscribe();
    };
  }, [account]);
  
  useEffect(()=>{
    if (import.meta.env.MODE === 'dev' && isMobile) {
      const vConsole = new VConsole();
    }
  },[])

  useEffect(() => {
		if (!store.get('LOCATOR_OPTIONS') || store.get('LOCATOR_OPTIONS')?.showInfto) {
			store.set('LOCATOR_OPTIONS', { showIntro: false });
			if (import.meta.env.MODE === 'dev') {
				setupLocatorUI();
			}
		} else if (import.meta.env.MODE === 'dev') {
			setupLocatorUI();
		}
	}, []);

  useSocket(account)
  // if (tokens.length == 0) {
  //   return <Suspense fallback={<div style={{ backgroundColor: localStorage.getItem('app_setting_theme') == 'light' ? '#fff' : '#16141A' }}>loading....</div>}>
  //   </Suspense>


const routeF = ({p, c}) => {
  return [
    <Route exact path={p} component={c} key={`${p}_en`}/>,
    <Route exact path={'/zh_cn' + p} component={c} key={`${p}_zh_cn`} />,
    <Route exact path={'/th' + p} component={c} key={`${p}_th`} />,
    <Route exact path={'/ko' + p} component={c} key={`${p}_ko`} />
  ]
}

  return (
    <Suspense fallback={<div style={{ backgroundColor: localStorage.getItem('app_setting_theme') == 'light' ? '#fff' : '#16141A' }}>loading....</div>}>
      <BrowserRouter>
        <div className="App" id="App">
          {/* <BrowserNotification /> */}
          <Nav />
          <div className="content" id="content">
            <Switch>
              {routeF({p: '/', c: Home}).map(item => (item))}
              {routeF({p: '/dashboard', c: Dashboard}).map(item => (item))}
              {routeF({p: '/trade/:coin?', c: tokens.length == 0 ? TransactionNone : TransactionParams}).map(item => (item))}
              {routeF({p: '/earn/stacking', c: tokens.length == 0 ? TransactionNone : Earn}).map(item => (item))}
              {/* {routeF({p: '/earn/meme-stacking', c: tokens.length == 0 ? TransactionNone : MemeEarn}).map(item => (item))} */}
              {/* {routeF({p: '/ecosystem', c: Ecology}).map(item => (item))} */}
              {routeF({p: '/referrals/:code?', c: RecommendShare}).map(item => (item))}
              {routeF({p: '/recommendSetting', c: RecommendSetting}).map(item => (item))}
              {routeF({p: '/aboutFundPool', c: AboutFundPool}).map(item => (item))}
              {routeF({p: '/earnMy', c: EarnMy}).map(item => (item))}
              {/* {routeF({p: '/meme-earnMy', c: MeMeEarnMy}).map(item => (item))} */}
              {/* {routeF({p: '/createChallenge', c: CreateChallenge}).map(item => (item))}
              {routeF({p: '/createFund', c: CreateFund}).map(item => (item))}
              {routeF({p: '/challengeDetail', c: ChallengeDetail}).map(item => (item))}
              {routeF({p: '/challenge', c: Challenge}).map(item => (item))} */}
              {/* {routeF({p: '/crossChain', c: tokens.length == 0 ? TransactionNone : CrossChain}).map(item => (item))} */}
              {/* {routeF({p: '/event-hub/trading-competition', c: TradingCompetition}).map(item => (item))}
              {routeF({p: '/event-hub/bonus-hunter', c: BountyHunter}).map(item => (item))} */}
              {routeF({p: '/event-hub/bug-bounty', c: BugCompetition}).map(item => (item))}
              {routeF({p: '/event-hub/trader-conquest', c: Avtivity}).map(item => (item))}
              {routeF({p: '/supernova', c: Supernova}).map(item => (item))}
              {routeF({p: '/legends', c: Legends}).map(item => (item))}
              {routeF({p: '/airdrop', c: Airdrop}).map(item => (item))}
              {routeF({p: '/coinInfo/:name', c: CoinInfo}).map(item => (item))}
              
              {/* <Route exact path="/" component={Home} />
              <Route exact path="/zh_cn" component={Home} />
              <Route exact path="/ko" component={Home} />
              <Route exact path="/th" component={Home} /> */}

              {/* <Route exact path="/dashboard" component={Dashboard} />
              <Route exact path="/zh_cn/dashboard" component={Dashboard} />
              <Route exact path="/ko/dashboard" component={Dashboard} />
              <Route exact path="/th/dashboard" component={Dashboard} /> */}

              {/* <Route path="/trade/:coin?" component={tokens.length == 0 ? TransactionNone : TransactionParams}/>
              <Route path="/zh_cn/trade/:coin?" component={tokens.length == 0 ? TransactionNone : TransactionParams}/>
              <Route path="/ko/trade/:coin?" component={tokens.length == 0 ? TransactionNone : TransactionParams}/>
              <Route path="/th/trade/:coin?" component={tokens.length == 0 ? TransactionNone : TransactionParams}/> */}
              
              {/* <Route exact path="/earn/stacking" component={tokens.length == 0 ? TransactionNone : Earn} />
              <Route exact path="/zh_cn/earn/stacking" component={tokens.length == 0 ? TransactionNone : Earn} />
              <Route exact path="/ko/earn/stacking" component={tokens.length == 0 ? TransactionNone : Earn} />
              <Route exact path="/th/earn/stacking" component={tokens.length == 0 ? TransactionNone : Earn} /> */}

              {/* <Route exact path="/earn/meme-stacking" component={tokens.length == 0 ? TransactionNone : MemeEarn} /> */}
              {/* <Route exact path="/zh_cn/earn/meme-stacking" component={tokens.length == 0 ? TransactionNone : MemeEarn} /> */}
              {/* <Route exact path="/ko/earn/meme-stacking" component={tokens.length == 0 ? TransactionNone : MemeEarn} /> */}
              {/* <Route exact path="/th/earn/meme-stacking" component={tokens.length == 0 ? TransactionNone : MemeEarn} /> */}

              {/* <Route exact path="/ecosystem" component={Ecology} /> */}
              {/* <Route exact path="/zh_cn/ecosystem" component={Ecology} /> */}
              {/* <Route exact path="/ko/ecosystem" component={Ecology} /> */}
              {/* <Route exact path="/th/ecosystem" component={Ecology} /> */}

              {/* <Route path="/referrals/:code?" component={RecommendShare}/>
              <Route path="/zh_cn/referrals/:code?" component={RecommendShare}/>
              <Route path="/ko/referrals/:code?" component={RecommendShare}/>
              <Route path="/th/referrals/:code?" component={RecommendShare}/> */}

              {/* <Route exact path="/recommendSetting" component={RecommendSetting} />
              <Route exact path="/zh_cn/recommendSetting" component={RecommendSetting} />
              <Route exact path="/ko/recommendSetting" component={RecommendSetting} />
              <Route exact path="/th/recommendSetting" component={RecommendSetting} /> */}

              {/* <Route exact path="/aboutFundPool" component={AboutFundPool} />
              <Route exact path="/zh_cn/aboutFundPool" component={AboutFundPool} />
              <Route exact path="/ko/aboutFundPool" component={AboutFundPool} />
              <Route exact path="/th/aboutFundPool" component={AboutFundPool} /> */}

              {/* <Route exact path="/earnMy" component={EarnMy} />
              <Route exact path="/zh_cn/earnMy" component={EarnMy} />
              <Route exact path="/ko/earnMy" component={EarnMy} />
              <Route exact path="/th/earnMy" component={EarnMy} /> */}

              {/* <Route exact path="/meme-earnMy" component={MeMeEarnMy} /> */}
              {/* <Route exact path="/zh_cn/meme-earnMy" component={MeMeEarnMy} /> */}
              {/* <Route exact path="/ko/meme-earnMy" component={MeMeEarnMy} /> */}
              {/* <Route exact path="/th/meme-earnMy" component={MeMeEarnMy} /> */}

              {/* <Route exact path="/createChallenge" component={CreateChallenge} />
              <Route exact path="/zh_cn/createChallenge" component={CreateChallenge} />
              <Route exact path="/ko/createChallenge" component={CreateChallenge} />
              <Route exact path="/th/createChallenge" component={CreateChallenge} /> */}

              {/* <Route exact path="/createFund" component={CreateFund} />
              <Route exact path="/zh_cn/createFund" component={CreateFund} />
              <Route exact path="/ko/createFund" component={CreateFund} />
              <Route exact path="/th/createFund" component={CreateFund} /> */}

              {/* <Route exact path="/challengeDetail" component={ChallengeDetail} />
              <Route exact path="/zh_cn/challengeDetail" component={ChallengeDetail} />
              <Route exact path="/ko/challengeDetail" component={ChallengeDetail} />
              <Route exact path="/th/challengeDetail" component={ChallengeDetail} /> */}

              {/* <Route exact path="/challenge" component={Challenge} />
              <Route exact path="/zh_cn/challenge" component={Challenge} />
              <Route exact path="/ko/challenge" component={Challenge} />
              <Route exact path="/th/challenge" component={Challenge} /> */}

              {/* <Route exact path="/crossChain" component={tokens.length == 0 ? TransactionNone : CrossChain} /> */}
              {/* <Route exact path="/zh_cn/crossChain" component={tokens.length == 0 ? TransactionNone : CrossChain} /> */}
              {/* <Route exact path="/ko/crossChain" component={tokens.length == 0 ? TransactionNone : CrossChain} /> */}
              {/* <Route exact path="/th/crossChain" component={tokens.length == 0 ? TransactionNone : CrossChain} /> */}

              {/* <Route exact path="/event-hub/trading-competition" component={TradingCompetition} /> */}
              {/* <Route exact path="/zh_cn/event-hub/trading-competition" component={TradingCompetition} /> */}
              {/* <Route exact path="/ko/event-hub/trading-competition" component={TradingCompetition} /> */}
              {/* <Route exact path="/th/event-hub/trading-competition" component={TradingCompetition} /> */}

              {/* <Route exact path="/event-hub/bonus-hunter" component={BountyHunter} /> */}
              {/* <Route exact path="/zh_cn/event-hub/bonus-hunter" component={BountyHunter} /> */}
              {/* <Route exact path="/ko/event-hub/bonus-hunter" component={BountyHunter} /> */}
              {/* <Route exact path="/th/event-hub/bonus-hunter" component={BountyHunter} /> */}

              {/* <Route exact path="/event-hub/bug-bounty" component={BugCompetition} />
              <Route exact path="/zh_cn/event-hub/bug-bounty" component={BugCompetition} />
              <Route exact path="/ko/event-hub/bug-bounty" component={BugCompetition} />
              <Route exact path="/th/event-hub/bug-bounty" component={BugCompetition} /> */}

              {/* <Route exact path="/event-hub/trader-conquest" component={Avtivity} />
              <Route exact path="/zh_cn/event-hub/trader-conquest" component={Avtivity} />
              <Route exact path="/ko/event-hub/trader-conquest" component={Avtivity} />
              <Route exact path="/th/event-hub/trader-conquest" component={Avtivity} /> */}

              {/* <Route exact path="/supernova" component={Supernova} />
              <Route exact path="/zh_cn/supernova" component={Supernova} />
              <Route exact path="/ko/supernova" component={Supernova} />
              <Route exact path="/th/supernova" component={Supernova} /> */}

              {/* <Route exact path="/legends" component={Legends} />
              <Route exact path="/zh_cn/legends" component={Legends} />
              <Route exact path="/ko/legends" component={Legends} />
              <Route exact path="/th/legends" component={Legends} /> */}
              <Redirect to="/" />
            </Switch>
          </div>
          <Footer />
        </div>
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
