import { create } from 'zustand';

const useSubAccountStore = create((set) => ({
  parentAccount: "",
  isSubConnected: false,
  isConnectionModal: false,
  setSubAccount: (subAccount) => set((state) => ({
    ...state,
    ...subAccount
  })),
  setConnectionModal: (modalState) => set((state) => ({
    ...state,
    ...modalState
  })),
}));

export default useSubAccountStore;