import { useMemo } from "react";
import { darkTheme, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import merge from "lodash/merge";
import i18next from "i18next";
import { getRainbowKitConfig } from "./rainbowKitConfig";
import { WagmiProvider } from "wagmi";

const walletTheme = merge(darkTheme(), {
  colors: {
    modalBackground: "#16182e",
    accentColor: "#9da5f2",
    menuItemBackground: "#808aff14",
  },
  radii: {
    modal: "4px",
    menuButton: "4px",
  },
});

const queryClient = new QueryClient();

const appLocale2RainbowLocaleMap = {
  EN: "en",
  ZH: "zh",
};

export default function WalletProvider({ children }) {
  const wagmiConfig = getRainbowKitConfig(); // 获取配置

  return (
    <QueryClientProvider client={queryClient}>
      <WagmiProvider config={wagmiConfig}>
        {children}
      </WagmiProvider>
    </QueryClientProvider>
  );
}

export function RainbowKitProviderWrapper({ children }) {
  const locale = useMemo(() => appLocale2RainbowLocaleMap[i18next.language] ?? "en", [i18next.language]);
  return (
    <RainbowKitProvider theme={walletTheme} locale={locale} modalSize="compact">
      {children}
    </RainbowKitProvider>
  );
}
