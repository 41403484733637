import { getChainName } from "../../config/chains";
import i18next from "i18next";
import { switchNetwork } from "../wallets";

export const INVALID_NETWORK_TOAST_ID = "invalid-network";
export function getInvalidNetworkErrorMessage(chainId) {
    return (
      <div>
        <div>{`${i18next.t('Your wallet is not connected to x', {x: getChainName(chainId)})}.`}</div>
        <br />
        <div className="clickable underline cursor-pointer" onClick={() => switchNetwork(chainId, true)}>
        {`${i18next.t('Switch to', {x: getChainName(chainId)})}`}
        </div>
      </div>
    );
  }