import { ethers } from "ethers";
import { getFallbackProvider, getProvider, getDeriwProvider } from "../rpc";

const CONTRACT_FETCHER_DEFAULT_FETCH_TIMEOUT = 2000;
const CONTRACT_FETCHER_WORKER_TIMEOUT = 10000;
const CONTRACT_FETCHER_MAIN_THREAD_TIMEOUT = 5000;

export const contractFetcher = (signer, contractABI, additionalArgs) =>
  (args, b) => {
    // eslint-disable-next-line
    const [id, chainId, arg0, arg1, ...params] = args;
    const provider = isProvider(signer) ? signer : getDeriwProvider();
    const method = ethers.utils.isAddress(arg0) ? arg1 : arg0;

    // console.log('Fetcher start:', method, id, chainId, arg0, arg1, provider, new Date().toLocaleString());
    const contractCall = getContractCall({
      provider,
      contractABI,
      arg0,
      arg1,
      method,
      params,
      additionalArgs,
    });

    let shouldCallFallback = true;

    const handleFallback = async (resolve, reject, error) => {
      if (!shouldCallFallback) {
        return;
      }
      // prevent fallback from being called twice
      shouldCallFallback = false;

      const fallbackProvider = getDeriwProvider();
      if (!fallbackProvider) {
        reject(error);
        return;
      }

      // eslint-disable-next-line no-console
      console.info("using fallbackProvider for", method);


      const fallbackContractCall = getContractCall({
        provider: fallbackProvider,
        contractABI,
        arg0,
        arg1,
        method,
        params,
        additionalArgs,
      });

      fallbackContractCall
        .then((result) => resolve(result))
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error("fallback fetcher error", id, "contract", method, e);
          reject(e);
        });
    };
    
    return new Promise(async (resolve, reject) => {
      contractCall
        .then((result) => {
          shouldCallFallback = false;
          // console.log('Fetcher end:',method, result, new Date().toLocaleString());
          resolve(result);
        })
        .catch((e) => {
          console.error("fetcher error", id, "contract", method, e);
          handleFallback(resolve, reject, e);
        });

      // 请求超时保护机制
      const timeout = CONTRACT_FETCHER_WORKER_TIMEOUT + CONTRACT_FETCHER_MAIN_THREAD_TIMEOUT;
      setTimeout(() => {
        handleFallback(resolve, reject, "contractCall timeout");
      }, timeout);
    });
  };

function getContractCall({ provider, contractABI, arg0, arg1, method, params, additionalArgs }) {
  if (ethers.utils.isAddress(arg0)) {
    const address = arg0;

    const contract = new ethers.Contract(address, contractABI, provider);
    if (additionalArgs) {
      return contract[method](...params.concat(additionalArgs));
    }
    return contract[method](...params);
  }
  if (!provider) {
    return;
  }
  return provider[method](arg1, ...params);
}

function isProvider(signerOrProvider) {
  if (!signerOrProvider) return false;
  return !!(signerOrProvider).populateCall;
}
