import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import config from "./config";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { SWRConfig } from "swr";
import "./i18n";
import "./index.css";
import 'antd/dist/antd.css';
import 'moment/locale/zh-cn'
import EventToastContainer from "./components/Toast/ToastContainer";
import { unstable_serialize } from "swr";
import WalletProvider, { RainbowKitProviderWrapper } from "./lib/wallets/WalletProvider";
import * as Sentry from '@sentry/react';


export let swrCache = new Map();
const counter = {};
const swrGCMiddleware =
  (useSWRNext) => (key, fetcher, config) => {
    const { clearUnusedKeys, cache } = config;
    const keyToWatch = clearUnusedKeys ? unstable_serialize(key) : undefined;

    useEffect(() => {
      if (!keyToWatch) {
        return;
      }

      counter[keyToWatch] = (counter[keyToWatch] || 0) + 1;

      return () => {
        counter[keyToWatch]--;

        if (clearUnusedKeys && !counter[keyToWatch]) {
          cache.delete(keyToWatch);
        }
      };
    }, [cache, clearUnusedKeys, keyToWatch]);

    return useSWRNext(key, fetcher, config);
};

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    // Sentry.replayIntegration()
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


createRoot(document.getElementById("root")).render(
  // <React.StrictMode>
      <WalletProvider>
        <RainbowKitProviderWrapper>
            <SWRConfig key={config.networkId}
              value={{
                refreshInterval: 5000,
                refreshWhenHidden: false,
                refreshWhenOffline: false,
                dedupingInterval:0,
                use: [swrGCMiddleware],
                provider: () => {
                  swrCache = new Map();
                  return swrCache;
                },
              }}
            >
              <App />
          </SWRConfig>
          <EventToastContainer />
        </RainbowKitProviderWrapper>
      </WalletProvider>
// </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
