import React, { useMemo, useState, useEffect } from "react";
import useSubAccountStore from "../store/useSubAccountStore";
import { useShallow } from 'zustand/react/shallow'

const useSubAccount = () => {
    const { isSubConnected, setConnectionModal } = useSubAccountStore(
        useShallow((state) => ({ isSubConnected: state.isSubConnected, setConnectionModal: state.setConnectionModal })),
    )

    const isSubAccountCheck = () => {
        if (!isSubConnected) {
            setConnectionModal({isConnectionModal:true})
        }
    };

    return {
        isSubConnected,
        isSubAccountCheck,
    };
};

export default useSubAccount;