import envDev from "./env-dev.js"; // 开发环境配置
import envPre from "./env-pre.js"; // 预发布环境配置
import envOnline from "./env-online.js"; // 线上环境配置（生成环境）
import envTestnet from "./env-testnet.js"; // 测试网环境配置

const ENV = import.meta.env.VITE_APP_ENV || "dev"; // dev, pre, online

let supportedChainIds = [42161, 421614];
let config = {};
if (ENV == "dev") {
  config = envDev;
} else if (ENV == "pre") {
  config = envPre;
} else if (ENV == "online") {
  config = envOnline;
} else if (ENV == "testnet") {
  config = envTestnet;
}


const baseConfig = {
  apiUrl: config.baseUrl + ""
};

const isDevelopment = () => {
  if (ENV == "dev") {
    return true;
  }
  return false
}

const isPre = () => {
  if (ENV == "pre") {
    return true;
  }
  return false
}

const isTestnet = () => {
  if (ENV == "testnet") {
    return true;
  }
  return false
}

export default {
  isDevelopment: isDevelopment,
  isPre: isPre,
  isTestnet: isTestnet,
  ...baseConfig,
  ...config,
  supportedChainIds
};
