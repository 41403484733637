import React, { Suspense, useEffect, useState } from 'react';

/**
 * 创建异步加载组件的包装器
 * @param {Function} importFunc - 动态导入组件的函数
 * @param {React.ComponentType} LoadingComponent - 加载时显示的组件
 * @returns {React.ComponentType} 返回包装后的组件
 */
export function lazyLoadWithLoading(importFunc, LoadingComponent) {
  const LazyComponent = React.lazy(importFunc);

  return function LazyLoadWrapper(props) {
    return (
      <Suspense fallback={<LoadingComponent />}>
        {/* <ErrorBoundary> */}
          <LazyComponent {...props} />
        {/* </ErrorBoundary> */}
      </Suspense>
    );
  };
}

/**
 * 错误边界组件
 */
export function ErrorBoundary({ children }) {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const handleError = (error) => {
      console.error('Lazyload Error:', error);
      setHasError(true);
    };

    window.addEventListener('error', handleError);
    window.addEventListener('unhandledrejection', handleError);

    return () => {
      window.removeEventListener('error', handleError);
      window.removeEventListener('unhandledrejection', handleError); 
    };
  }, []);

  if (hasError) {
    return <div>Something went wrong.</div>;
  }

  return children;
}

/**
 * 批量创建异步路由配置
 * @param {Object} routes - 路由配置对象
 * @param {React.ComponentType} LoadingComponent - 加载时显示的组件
 * @returns {Object} 处理后的路由配置
 */
export function createLazyRoutes(routes, LoadingComponent) {
  return Object.keys(routes).reduce((acc, key) => {
    acc[key] = lazyLoadWithLoading(routes[key], LoadingComponent);
    return acc;
  }, {});
}