import React, { useState, useEffect, useRef, useMemo } from "react";
import { NETWORK_METADATA } from '../../../config/chains'
import config from "../../../config";
import "../index.scss";
import { utils, providers, Wallet } from 'ethers'
import { useTranslation } from "react-i18next";
import { ShowToast, Info, Success, Warning } from "../../Toast";
import { formatAmount } from "../../../utils/numbers";
import { formatCoinAmount } from "../../../utils";
import dayjs from "dayjs";
import { CountTime } from '../../Time'
import useWalletDeriw from "../../../utils/wallets/useWalletDeriw";

// e.stopPropagation()
const TransactionTd = ({ transaction, tab, getHistory, refNetworks, refNetworkLayers, pendingWithdrawal, setPendingWithdrawal }) => {
  const web3param = useWalletDeriw();
  const { library, account, chainId, active } = web3param;
  const { t } = useTranslation();
  const [claimLoading, setClaimLoading] = useState(false)

  useEffect(() => {
    return () => {
      setClaimLoading(false)
    }
  }, [])

  return (
    <div className="SettingsModal_table_head">
      <div className="SettingsModal_table_head_item SettingsModal_table_td_item ">
        {
          transaction.status == "2" ?
            t('Cross_已完成') :
                t('处理中')
        }
      </div>
      <div className="SettingsModal_table_head_item SettingsModal_table_td_item SettingsModal_table_td_item1">
        {dayjs(transaction.start_timestamp * 1000).format('D/M/YYYY')}
        <br />
        {dayjs(transaction.start_timestamp * 1000).format('h:m:s A')}
      </div>
      <div className="SettingsModal_table_head_item SettingsModal_table_td_item SettingsModal_table_td_item2">{formatAmount(transaction.amount, transaction.decimals, formatCoinAmount(formatAmount(transaction.amount, transaction.decimals, transaction.decimals)))}{" "}{transaction.symbol}</div>
      <div className="SettingsModal_table_head_item SettingsModal_table_td_item SettingsModal_table_td_item3">
        <img src={`${config.netWorkIcon}${transaction.FromChainId}.png`} className="SettingsModal_table_td_item_img" />
        <div className="SettingsModal_table_td_item_text">
          {
            NETWORK_METADATA[transaction.FromChainId] ? NETWORK_METADATA[transaction.FromChainId].chainName : ''
          }
        </div>
      </div>
      <div className="SettingsModal_table_head_item SettingsModal_table_td_item SettingsModal_table_td_item3">
        <img src={`${config.netWorkIcon}${transaction.ToChainId}.png`} className="SettingsModal_table_td_item_img" />
        <div className="SettingsModal_table_td_item_text">
          {
            NETWORK_METADATA[transaction.ToChainId] ? NETWORK_METADATA[transaction.ToChainId].chainName : ''
          }
        </div>
      </div>
      <div className="SettingsModal_table_head_item SettingsModal_table_td_item SettingsModal_table_td_item4">
        {
          transaction.status == 2 ?
            0 : 
            <CountTime
              times={transaction.end_timestamp - dayjs().unix()}
              onEndTimeHandle={() => getHistory(2)}
              format="text"
              endText={true}
            />
        }
      </div>
    </div>
  );
};

export default TransactionTd;
