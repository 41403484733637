import React, { useEffect, useState } from "react";
import Logo from '../../assets/images/mint_button_logo.png'
import LogoLight from '../../assets/images/mint_button_logo_light.png'
import { Info, ShowToast, Warning } from "../Toast";
import { ethers } from "ethers";
import "./index.scss";
import { useTranslation } from "react-i18next";
import useWalletDeriw from "../../utils/wallets/useWalletDeriw";
import { getContract } from "../../config/contracts";
import { callContract } from "../../utils/contracts";
import MintAirdropABI from "../../contracts/abi/MintAirdrop.json";
import { useChainId } from "../../utils/chains";
import useModeStore from "../../store/useModeStore";
import { useShallow } from 'zustand/react/shallow';
import useSubAccount from "../../hooks/useSubAccount"
const ToastId = "Toast_Transaction"

const MintButton = props => {
    const { t } = useTranslation();
    const { chainId } = useChainId();
    const { mode } = useModeStore(useShallow((state) => ({ mode: state.mode })))

    const { isSubConnected, isSubAccountCheck } = useSubAccount()
    const [ minting, setMinting ] = useState(false)
    const [ userInfo, setUserInfo ] = useState(undefined)
    const { account, library } = useWalletDeriw()
    const contractAddress = getContract(chainId, "MINT_AIRDROP");
    const contract = new ethers.Contract(contractAddress, MintAirdropABI, library?.getSigner());
    
    const fetchUserInfo = async () => {
        try {
            const userInfo = await contract.getUserInfo(account);
            setUserInfo(userInfo);
        } catch (error) {
            console.error("Failed to obtain user information:", error);
        }
    };
    /**
     * 使用 useEffect 钩子来获取用户信息
     * 当 contractAddress 变化时，重新获取用户信息
     */
    useEffect(() => {
        if (!userInfo && account && library && contractAddress && contract) {
            fetchUserInfo();
        }
    }, [contractAddress, contract, account, library, MintAirdropABI]);

    const mint = async () => {
        if (!isSubConnected && account) {
            isSubAccountCheck();
            return
        }
        if (!account) {
            ShowToast(ToastId, t('提示'), Warning, [t('无法获取地址')])
            return
        }
        if (minting) {
            return
        }
        setMinting(true)
        
        if (userInfo && userInfo?.lastTime?.gt(0)) {
            setMinting(false)
            ShowToast(ToastId, t('提示'), Warning, [t('已经领取')])
            return
        }
        ShowToast(ToastId, t('提示'), Warning, [t('铸造中')])
        
        callContract(chainId, contract, "claim", [], {
            pendingMsg: t(`铸造中`) + "...",
            sentMsg: t(`铸造中`) + "...",
            failMsg: t(`铸造失败`),
            successMsg: t(`mint_success`),
            hideSuccessMsg: false,
            gasless: true,
        }, null, contractAddress, MintAirdropABI).then(async (respond) => {
            if (respond && respond.wait) {
                await respond.wait()
            }
        }).catch((e) => {
            console.error("e", e)
        }).finally(() => {
            fetchUserInfo();
            setMinting(false)
        })
    }

    if (!account) {
        return <></>
    }

    return (
        <div className="home_mint_button" onClick={mint}> <img
            src={mode == 'dark' ? Logo : LogoLight}
            alt=""
            className=""
        />
            {t('测试代币')}
        </div>
    );
};

export default MintButton;
