import { Fragment, useEffect, useRef, useState } from "react";
import { Menu, Transition, Dialog } from "@headlessui/react";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import store2 from 'store2';
import { useMedia } from "react-use";
import copy from "copy-to-clipboard";
import { CURRENT_PROVIDER_LOCALSTORAGE_KEY, SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY } from "../../config/localStorage";
import useWalletDeriw from '../../utils/wallets/useWalletDeriw'
import useRandomPrivateKey from '../../hooks/useRandomPrivateKey'
import useHyperConnection from '../../hooks/useHyperConnection'
import IconWallet from "../../assets/svg/icon-wallet.svg"
import CopyNewImg from "../../assets/images/copy_new.png"
import BrowserNewImg from "../../assets/images/browser_new.png"
import Loginout from "../../assets/images/loginout.png"
import FrameDark from "../../assets/images/frame_dark.png"
import FrameLight from "../../assets/images/frame_light.png"
import ModelCloseM from "../../assets/images/model_close_m.png";
import ModelCloseMLight from "../../assets/images/model_close_m_light.png";
import useModeStore from "../../store/useModeStore";
import { useShallow } from 'zustand/react/shallow';
import Browser from "../../assets/images/browser.png";
import { useTranslation } from "react-i18next";
import { screenIs } from "../../utils/theme"
import { shortenAddress } from "../../utils/legacy";
import { toggleBackDrop } from "../../utils/useBackDrop";
import { getExplorerUrl } from "../../utils";
import { useChainId } from "../../utils/chains";
import Transactions from "../../assets/images/transactions.png"
import TransactionsL from "../../assets/images/transactions_l.png"
import TransactionsModal from '../TransactionsModal'
import { ShowToast, Success } from "../Toast";
import { getTokens } from "../../config/token";
import { useDisconnect } from "wagmi";
import config from "../../config";
import { NETWORK_METADATA } from "../../config/chains";

export default function WalletDropdown() {
  const { account, active, connector } = useWalletDeriw();
  const { openConnectModal } = useConnectModal();
  const { disconnect } = useDisconnect();
  const { chainId } = useChainId();
  const url = getExplorerUrl(chainId || 0, "network");
  const { mode } = useModeStore(useShallow((state) => ({ mode: state.mode })))
  const { t } = useTranslation();
  const {createSubAccount} = useRandomPrivateKey();
  const {hasSessionConnect} = useHyperConnection();
  const [isTransactionsModal, setTransactionsModal] = useState(false)

  const disconnectAccountAndCloseSettings = () => {
    disconnect();
    localStorage.removeItem(SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY);
    localStorage.removeItem(CURRENT_PROVIDER_LOCALSTORAGE_KEY);
    // window.location.reload()
  };

  const view = (isDeriw) => {
    if (isDeriw) {
      window.open(config.explorer + "address/" + account)
    } else {
      window.open(url + "address/" + account)
    }
  }
  const below960 = useMedia("(max-width: 960px)");

  useEffect(() => {
    if (account && active && !hasSessionConnect) {
      createSubAccount();
    }
    if(account){
      store2.set('userAddress',account);
    }
  }, [account, active, hasSessionConnect])

  const copyText = (text) => {
    copy(text)
    ShowToast('copy', t("提示"), Success, [t('复制成功')])  
  }

  return (
    <>
      {
        isTransactionsModal &&
        <TransactionsModal onClose={() => setTransactionsModal(false)} />
      }
      <Menu>
        {({ open }) => {
          return <>
            {/* <Control open={open}></Control> */}
            <Menu.Button 
              className={["walletconnect_box", !active ? 'walletconnect_active' : ''].join(' ')}>
              <NavIcons showWalletModal={()=> openConnectModal?.()}/>
            </Menu.Button>

            {/* Use the `Transition` component. */}
            <Transition
              // className={"absolute z-20"}
              show={open}
            >
              {
                active &&
                <Menu.Items className="walletconnect_dot">

                  {
                    below960 &&
                    <Menu.Item>
                      <div className="walletconnect_item_head">
                        <div className="walletconnect_item_head_left">
                        </div>

                        <img className="close" src={mode == 'dark' ? ModelCloseM : ModelCloseMLight} />
                      </div>
                    </Menu.Item>
                  }
                  {
                    // !below960 &&
                    <Menu.Item>
                      <div className={["walletconnect_item_account_box", "walletconnect_item_account_box_nomne"].join(' ')}>

                        <div className="walletconnect_item_account_line">
                          <div className="walletconnect_item_account_line_left">
                            <div className="walletconnect_item_account_line_left_img_box">
                              <img src={connector?.icon ? connector?.icon : IconWallet} className={connector?.icon ? "": "!w-4"}/>
                            </div>
                            <div>
                              <div className="walletconnect_item_account_line_left_top">
                                <span className="w">{t('钱包地址')}</span>
                                {/* <Tooltip title={t(`您的 Deriw 地址“”根据以太坊地址“${shortenAddress(account, 12)}”的签名创建。`)}>
                                  <span className="td">{t('链地址')}</span>
                                </Tooltip> */}
                              </div>
                              <div className="walletconnect_item_account_line_left_bot">
                                {shortenAddress(account, 12)}
                              </div>
                            </div>

                          </div>

                          <div className="walletconnect_item_account_line_right">
                            <div className="icon_box" onClick={() => copyText(account)}>
                              <img src={CopyNewImg} />
                            </div>
                            <div className="icon_box" onClick={() => view()}>
                              <img src={BrowserNewImg} />
                            </div>
                          </div>
                        </div>


                      </div>
                    </Menu.Item>
                  }
                  {/* {
                  below960 &&
                  <Menu.Item>
                    <div className="walletconnect_item_head">
                      <div className="walletconnect_item_head_left">
                        <div className="photo_box">
                          <img src={FrameDark} />
                        </div>

                        {shortenAddress(account || "", 10)}
                        <img src={MCopy} className="copy" onClick={() => copyText(account)} />
                      </div>

                      <img className="close" src={mode == 'dark' ? ModelCloseM : ModelCloseMLight} />
                    </div>
                  </Menu.Item>
                } */}

                  <Menu.Item>
                    <div className="walletconnect_item" onClick={() => setTransactionsModal(true)}>
                      <div className="walletconnect_item_left"><img className="icon" src={mode == 'dark' ? Transactions : TransactionsL} />{t('跨链记录')} </div>
                    </div>
                  </Menu.Item>
                  <Menu.Item>
                    <div className="walletconnect_item" onClick={() => view(true)}>
                      <div className="walletconnect_item_left"><img src={Browser} className="icon" />{t('在浏览器中查看x', {x: 'Deriw Testnet'})}</div>
                    </div>
                  </Menu.Item>
                  {/* <Menu.Item>
                    <div className="walletconnect_item" onClick={() => view(false)}>
                      <div className="walletconnect_item_left"><img src={Browser} className="icon" />{t('在浏览器中查看x', {x: 
    NETWORK_METADATA && NETWORK_METADATA[chainId] ? NETWORK_METADATA[chainId].chainName : ''})}</div>
                    </div>
                  </Menu.Item> */}
                  <Menu.Item>
                    <div className="walletconnect_item" onClick={() => { disconnectAccountAndCloseSettings() }}>
                      <div className="walletconnect_item_left"><img src={Loginout} className="icon" />{t('退出')}</div>
                    </div>
                  </Menu.Item>
                </Menu.Items>
              }
            </Transition>
          </>
        }}
      </Menu>
    </>
  );
}

function NavIcons({ showWalletModal }) {
  const { active, account } = useWalletDeriw()
  const { t } = useTranslation();
  const { mode } = useModeStore(useShallow((state) => ({ mode: state.mode })))
  const below960 = useMedia("(max-width: 960px)");

  return (
    <div className={["wallet-dropdown", "w-full", "h-full","shrink-0", "flex", "flex-row", "items-center", "px-1", "bg-blueGrey hover:bg-lightPurple", active && 'wallet-dropdown-m'].join(' ')} onClick={() => showWalletModal ? showWalletModal(true) : null
    }>
      {
        active && <img src={below960 ? FrameDark : mode == 'dark' ? FrameDark : FrameLight} alt="wallet" className="w-5 h-5 md:w-6 md:h-6 md:ml-2 active_img" />
      }
      {
        (!active || !account) && below960 ? <div className="nactive_img">{t('连接钱包')}</div> :  
        (!below960 && <div className={["w-full", "whitespace-nowrap", "text-greyBackground", "font-medium", "text-sm", "leading-4", "mx-1", "md:mr-2", "nactive_img", "text-center", active ? "wallet_text" : '',].join(' ')}>
          {(!active || !account) ? t('连接钱包') : shortenAddress(account || "", 10)}
        </div>)
      }
    </div>
  );
}


function Control({ open }) {
  useEffect(() => {
    toggleBackDrop({ id: "WalletDrodown", isShow: open })
  }, [open])
  return <div></div>
}
