import { HYPER_PERSIST_TRADING_CONNECTION } from "../legacy"
import { isEmpty } from "lodash"
import store2 from "store2"

// metadata: NetworkMetadata
export const addNetwork = async (metadata) => {
};

// chainId: number, _?: boolean
export const switchNetwork = async (chainId, active) => {
};

/**
 * @description 获取当前的子钱包信息
 * @param {string} account - 用户账户地址
 * @param {string} userAddress - 父账户地址
 * @returns {Object} connectedSubWallet - 当前连接的子钱包信息
 */
export function getConnectedSubWallet(account) {
  if (!account) return {};
  
  const HYPER_USER_CONNECTION = `deriw_agent_${account}`;
  const sessionConnect = store2.session.get(HYPER_USER_CONNECTION) || {};
  const localConnect = store2.get(HYPER_USER_CONNECTION) || {};
  const localTradingConnection = store2.get(HYPER_PERSIST_TRADING_CONNECTION);

  if (!isEmpty(localConnect) && localTradingConnection) {
      return localConnect;
  }

  if (!isEmpty(sessionConnect)) {
      return sessionConnect;
  }

  return {};
}

/**
 * @description 获取本地存储的用户地址
 * @returns {string} 用户地址
 */
export function getUserAddress() {
    return store2.get('userAddress') || '';
}