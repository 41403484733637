import { ethers } from "ethers";
const { parseEther } = ethers.utils;

export default {
  networkId: 2885, // 1:主网, 3：Ropsten测试链, 128: 火币主网,256: 火币测试链 42：kovan测试
  baseUrl: "https://api.test.deriw.com",
  testTransactionUrl: "https://test.deriw.com/trading-prop/trade",
  deriwTransactionUrl: "https://www.deriw.com/trading-prop/trade",

  aboutFundPoolDetailUrl: "https://docs.deriw.com/liquidity/liquidity-on-v1",
  klineUrl: "https://chart.test.deriw.com/",
  duneUrl: "https://dune.com/deriw_com/deriw-analytics",
  wsUrl: "wss://api.test.deriw.com/ws",
  explorer: "https://explorerv2.test.deriw.com/",
  tx: 'https://explorerv2.test.deriw.com/tx/',
  deriwExplorer: "https://explorerv2.test.deriw.com/",
  deriwTx: 'https://explorerv2.test.deriw.com/tx/',
  tokenAssetBaseUrl: "https://oss.deriw.com/tokens/",
  shareBaseUrl: "https://fh-dex.oss-ap-southeast-1.aliyuncs.com/fh-dex/client/invitation/",

  telegramgb: "https://t.me/deriwofficial",
  telegram: "https://t.me/deriwfinance",
  twitter: "https://x.com/DeriWOfficial",
  medium: "https://medium.com/@DeriwFi",
  discord: "https://discord.com/invite/deriwfinance",
  coinmarketcap: "https://coinmarketcap.com/community/profile/Deriw/",
  github: "https://github.com/deriwfi",
  docs: "https://docs.deriw.com",
  auditReport: "https://skynet.certik.com/projects/deriw-finance",

  sergentPrice: 99, // 100 usdt
  lieutenantPrice: 199, // 300 usdt
  chainName: 'Deriw Testnet',
  SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0005"),
  INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0005"),
  netWorkIcon: "https://oss.deriw.com/chain/",
  ethRpc: "https://rpc.test.deriw.com",

  mainChainAddr: "0x0000000000000000000000000000000000000000", // 主链币是：BSC
  // mainChainAddr: "0x21007fbfe34972C9B968a4AB1AB94AfdE9170244", // 主链币是：BSC

  address0: "0x0000000000000000000000000000000000000000",
  UnBindReferral: "0x0000000000000000000000000000000000000000000000000000000000000000", // 邀请解除绑定传的地址
  isRepeatCode: "0x0000000000000000000000000000000000000000", // 生成邀请码时校验 等于0地址 邀请码就不重复
  
  Vault: "0x7BA4628a51Fb362b8807954bd03a0c9527eD4A78",
  Router: "0x871cC36e0C52d3e41f896FF9D4eE03BC81E452e4",
  VaultUtils: "0x5A916A75b4f8a9DFecfFB85639C503Fb5A5A7cc1",
  VaultReader: "0x0F8A0C1F71733c338579f6e4D5eDcBCE54d8702e",
  Reader: "0xD105984cA3939df72EF99bE81c141a2E43c14E77",
  GlpManager: "0x2697c487ED1c2AB8A26586aB378f8B03A3ADa338",
  GlpRewardRouter: "0x33766Bb57c4a9cE9Fe3D17cf3569FCf3FBf211c2",
  NATIVE_TOKEN: "0x89b80e784F85028C75A00971B1A3f5827DC0d0aa", // (WETH)
  GLP: "0xE6071653F3100d2d453b51bFf34E1f0cdcC19129",
  OrderBook: "0x86240eD0A5B9067BD5ED1fDAA7c8f35f46B68794",
  OrderBookReader: "0xfdA0dccdb57e3526d10Ea4aA6ed00a20EEe3f65f",

  PositionRouter: "0x9E649615947e2bd1462A4b5D66645912b7740DB1",
  ReferralStorage: "0xB26BeCc14674E2aA75887BD9C05a32B556Ff23C5",
  ReferralData: "0xA64cEA6350C734c5bFBC50c91635A0CA9ad12626",
  ReferralReader: "0xfcd5ddd1B944910b94Dc232eA68b6bcd33D5c422",
  Timelock: "0xCceA0ff2ba16e111C1f687265Ae4da1561DFa096",
  Slippage: "0x5c92E7Ad1E867Ca07fEA6818F63200d0989Ae651",

  Phase: "0xb7D09f5a68551db53B37cd5aD3f90F3d9F938478",
  AuthV2: "0x944DB5ffD5a893027a973CB6c17daf64e6344AD3",
  
  FoundFactoryV2: "0xF9122F20073fa245ccAEE3dbe05e441915beec38",
  PoolDataV2: "0x9702EE4cdA12CFe25753b9b9489D866a8C1DbB65",
  LpCounter: "0xC4ffb7101750128354B49B431Fac43cd3F69D90d",
  ErrorContractV2: "0x53b2Ff7ad1dAb4f069C65b62dAb44315096b3385",
  FoundRouterV2: "0x92fB983531d747259fac904728F503b2cECf6ad9",
  FoundReader: "0x6cDe210B5d5Ed28289376F72fdF0d26f73f77558",
  // 测试币领取
  MINT_AIRDROP: "0xb207FD6D1136b0a32E2077754C971A82C7E54545",
  // Meme资金池
  MemeData: "0x64356664a4946Ef37d20CBc955C19b0FB0b0751e",
  MemeErrorContract: "0x781e1274846dFEe0445b41c5633Fa7Ac1c5e9C90",
  MemeFactory: "0xB1EF1FD48c92477553671a5202951c3897d1D09E",
  MemeRouter: "0x85E7037510a9153Bd4b8b110d56F3E25fc23dEd8",
  // Hyper 
  DeriwSubAccountPublic: "0x00000000000000000000000000000000000007E9",

  TestAuth: "0x6e6F13D2706D861F539925A5Af18893FB3D1D79C",
  TestPrpoFactory: "0xE9F045f0CE5dc1AD552e20E8df668194d67f95D5",
  TestPropTradeData: "0x305507D45D5441B81F5dD8FF9f00f65e0B392e86",
  TestErrContract: "0x3D343Fc0F6c6D2E047ec5e16e39A9b6A2031B9Ac",
  TestPropTradeRouter: "0x4D778dE09f5C043677bd18888114A9a0911dCE96",
  
  UserL2ToL3Router: "0x301A099287D53c1C14C273a0E02B8fe21886F8dc",
  UserL3ToL2Router: "0x63832A9d15D35EA9e275031c047cB3169eEe1fe9",

  fundMinDeposit: {
    USDT: 10,
    ETH: 0.005,
    BNB: 0.044,
    UNI: 2,
    LINK: 1,
    UNISWAP: 2,
    WBTC: 0.0003,
    PENGU: 10
  }
};
