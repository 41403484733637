import { ethers } from "ethers";
const { parseEther } = ethers.utils;

export default {
  networkId: 8449, // 1:主网, 3：Ropsten测试链, 128: 火币主网,256: 火币测试链 42：kovan测试
  baseUrl: "https://testgmxapi.weequan.cyou",
  testTransactionUrl: "https://dev.deriw.com/trading-prop/trade",
  deriwTransactionUrl: "https://www.deriw.com/trading-prop/trade",

  aboutFundPoolDetailUrl: "https://docs.deriw.com/liquidity/liquidity-on-v1",
  klineUrl: "https://chart.test.deriw.com/",
  duneUrl: "https://dune.com/deriw_com/deriw-analytics",
  wsUrl: "wss://testgmxapi.weequan.cyou/ws",
  explorer: "https://explorer.dev.deriw.com/",
  tx: 'https://explorer.dev.deriw.com/tx/',
  deriwExplorer: "https://explorer.deriw.com/",
  deriwTx: 'https://explorer.deriw.com/tx/',
  tokenAssetBaseUrl: "https://oss.deriw.com/tokens/",
  shareBaseUrl: "https://fh-dex.oss-ap-southeast-1.aliyuncs.com/fh-dex/client/invitation/",

  telegramgb: "https://t.me/deriwofficial",
  telegram: "https://t.me/deriwfinance",
  twitter: "https://x.com/DeriWOfficial",
  medium: "https://medium.com/@DeriwFi",
  discord: "https://discord.com/invite/deriwfinance",
  coinmarketcap: "https://coinmarketcap.com/community/profile/Deriw/",
  github: "https://github.com/deriwfi",
  docs: "https://docs.deriw.com",
  auditReport: "https://skynet.certik.com/projects/deriw-finance",

  sergentPrice: 99, // 100 usdt
  lieutenantPrice: 199, // 300 usdt
  chainName: 'Deriw Testnet',
  SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0005"),
  INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0005"),
  netWorkIcon: "https://oss.deriw.com/chain/",
  ethRpc: "https://rpc.dev.deriw.com",

  mainChainAddr: "0x0000000000000000000000000000000000000000", // 主链币是：BSC
  // mainChainAddr: "0x21007fbfe34972C9B968a4AB1AB94AfdE9170244", // 主链币是：BSC

  address0: "0x0000000000000000000000000000000000000000",
  UnBindReferral: "0x0000000000000000000000000000000000000000000000000000000000000000", // 邀请解除绑定传的地址
  isRepeatCode: "0x0000000000000000000000000000000000000000", // 生成邀请码时校验 等于0地址 邀请码就不重复
  
  Vault: "0xb96E719B01192D4F914E96A2Dd02B6A82BDB3EC2",
  Router: "0x5f211ec83633eFE9e9B4473Cd664BBf095330D96",
  VaultUtils: "0xF5EC397E208f993bfD541fCBecAEA337d65D6b9b",
  VaultReader: "0x7E578FF90785CF7BcB337Bd133a27dE959Eb4A7A",
  Reader: "0xcC8dDb9b808391c6aCaD53c13227695A65f5bA04",
  GlpManager: "0x3069d22b6Fe14a7a097D059b4652537Cd2ea7974",
  GlpRewardRouter: "0x40004A135E437A92d6fABe078Af9779d6Dd986d0",
  NATIVE_TOKEN: "0x80FD2baB832D0d9D248bDbBbb451181400FF1f29", // (WETH)
  GLP: "0x52F225C7C6C8814c688f622ec955196169aD8c16",
  OrderBook: "0x08E2007c13820aa44AaB513932E2564d66909CA4",
  OrderBookReader: "0xE1FaABeeDf9103C02B75f266d2429BdF288EF2Ac",

  PositionRouter: "0x957a4C319115344264d3F714B49bB18F36001C8d",
  ReferralStorage: "0x19fB0794ad391e1798D565472F905A79Fc281eb7",
  ReferralData: "0x1498CF7a56DdEc77a691DD380EcC062eD7aC05Ea",
  ReferralReader: "0xfcd5ddd1B944910b94Dc232eA68b6bcd33D5c422",
  Timelock: "0xcBd99c196A03Ff0E60c042806552C2d3DC13Ed99",
  Slippage: "0xe4896C3cb08e0D8F6D0936e343D7BD0378Aa69dA",

  Phase: "0xB082cD7bb0f2cdFBe6574a8A4d9E25356a251208",
  AuthV2: "0x2685fe0aD0a12E758eA240025A5D2856a92242c0",
  FoundFactoryV2: "0x9Aa7283ED3E6AD591d723CA931dED3bbA37b3081",
  PoolDataV2: "0x39bC2655682D0B5257174Da1ad82A8C2d2Ba4536",
  LpCounter: "0xC64E54B42EFF7249F5AfC2Dd25351Ca5511231D2",
  ErrorContractV2: "0xE8b8b113984dd844Be31396F21C249Eea7a128D3",
  FoundRouterV2: "0x0fa65982916E9f5c93f59BA440B58cd759E5F7Dc",
  FoundReader: "0x30828e4E5bE2CFe365f9a4b746ef8A65095aE7c7",
  // 测试币领取
  MINT_AIRDROP: "0xDAD7124fc2A8C90B2446801cA807338537650c4D",
  // Meme资金池
  MemeData: "0x4dD92F647385f956E52570f6A3b55e6F2d069a7C",
  MemeErrorContract: "0x8feEAd289382a324ab6347BA77b86319F0Ee9f28",
  MemeFactory: "0xFc8CF596f36e1CFEf8eA76B16A1FF43a8d7442aa",
  MemeRouter: "0xa04689E2C496Dd711a9dFfDB460DE7367368f1F0",
  // Hyper 
  DeriwSubAccountPublic: "0x00000000000000000000000000000000000007E9",
  // Multicall 
  Multicall3: "0x45Aa92b84358BA2105271C4bB33d7dbD4AC3b822",

  TestAuth: "0x6e6F13D2706D861F539925A5Af18893FB3D1D79C",
  TestPrpoFactory: "0xE9F045f0CE5dc1AD552e20E8df668194d67f95D5",
  TestPropTradeData: "0x305507D45D5441B81F5dD8FF9f00f65e0B392e86",
  TestErrContract: "0x3D343Fc0F6c6D2E047ec5e16e39A9b6A2031B9Ac",
  TestPropTradeRouter: "0x4D778dE09f5C043677bd18888114A9a0911dCE96",
  
  L1ToL2Router: "0x89b80e784F85028C75A00971B1A3f5827DC0d0aa",
  L2ToL3Router: "0xF66e0aBaba7e61968bF31fd75eEb39b379EEC954",
  L3ToL2Router: "0x09167d5D48bd6C95a50cce5E1B163a2017Ea1470",
  L2ToL1Router: "0x37C6A3b3C1E5b64071982dEfd092D8E02eC55118",
  UserL2ToL3Router: "0x610AE987E3C2689E6353c5375c9f3A4d7A129070",
  UserL3ToL2Router: "0x9305c64c63Da17308e151eC545F1665d0047BbC0",
  ValidateSign: "0x6e465324A7d113389De95183Db8Cfb76d411625c",

  fundMinDeposit: {
    USDT: 10,
    ETH: 0.005,
    BNB: 0.044,
    UNI: 2,
    LINK: 1,
    UNISWAP: 2,
    WBTC: 0.0003,
    PENGU: 10
  }
};
