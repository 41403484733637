import React, { useState, useEffect } from "react";
import "./index.scss";
import NoDataImg from '../../assets/images/nodata.png'
import NoDataWImg from '../../assets/images/nodata_w.svg'
import { useTranslation } from "react-i18next";
import useModeStore from "../../store/useModeStore";
import { useShallow } from 'zustand/react/shallow';

const NoData = ({ type }) => {
  const { t } = useTranslation();
  const { mode } = useModeStore(useShallow((state) => ({ mode: state.mode })))

  return (
    <div className="nodata_box">
      <img src={mode == 'dark' ? NoDataImg : NoDataWImg} />
      {
        type == 'loading' ?
          t('加载中') :
          t('暂无数据')
      }
    </div>
  );
};

export default NoData;
