import React, { useMemo, useState, useEffect } from "react";
import CModal2 from "../common/CModal2";
import { Info, ShowToast, Warning } from "../../components/Toast";
import { useTranslation } from "react-i18next";
import {Switch, Tooltip} from 'antd'
import "./index.scss";
import useHyperConnection from '../../hooks/useHyperConnection'
import { getContract } from "../../config/contracts";
import { useChainId } from "../../utils/chains";
import useWalletDeriw from "../../utils/wallets/useWalletDeriw";
import { callContract } from "../../utils/contracts";
import { isSupportedChain } from "../../config/chains";
import dayjs from "dayjs";
import { ethers } from "ethers";
import DeriwSubAccountPublicABI from '../../contracts/abi/DeriwSubAccountPublic.json'
import i18next from "i18next";
import { getAccountControl, HYPER_PERSIST_TRADING_CONNECTION } from '../../utils/legacy'
import useSubAccountStore from "../../store/useSubAccountStore";
import { useShallow } from 'zustand/react/shallow'
import { Buffer } from 'buffer';
import { useAccount } from "wagmi";
import { switchNetwork } from "../../lib/wallets";

const ConnectionModal = props => {
  const { onClose, visible } = props
  const { t } = useTranslation()
  const { account, active, subAccount, library, signer, walletClient } = useWalletDeriw();
  const { sessionConnect, setHyperStorage } = useHyperConnection();
  const [isRememberMe, setRememberMe] = useState(true)
  const { chainId } = useChainId()
  const { chainId:currentChain } = useAccount()
  const deriwSubAccountPublicAddress = getContract(chainId, "DeriwSubAccountPublic")
  const timeUnix = dayjs().unix()
  const HYPER_USER_CONNECTION = `deriw_agent_${account}`
  const { AddressZero } = ethers.constants;
  const { setSubAccount } = useSubAccountStore(
    useShallow((state) => ({ setSubAccount: state.setSubAccount})),
  )

  const [domain, types, message, signMessageData] = useMemo(() => {
    const typesDomain = [
      { name: "name", type: "string" },
      { name: "version", type: "string" },
      { name: "chainId", type: "uint256" },
      { name: "verifyingContract", type: "address" }
      ]
		const domainConfig = {
			name: "DeriwSubAccountSignature",
			version: "1",
			chainId: chainId,
			verifyingContract: deriwSubAccountPublicAddress
		};

		const typesConfig = {
			Message: [
				{ name: "Timestamp", type: "string" },
        { name: "Operation", type: "string" },
        { name: "Child", type: "address" },
			]
		};

    const typesConfigNoPrimaryType = [
      { name: "Timestamp", type: "string" },
      { name: "Operation", type: "string" },
      { name: "Child", type: "address" },
    ]

    const messageConfig = {
      Timestamp: `${timeUnix}`,
      Operation: "Grant", // Revoke // Grant
      Child: subAccount
    };

    const signMessageConfig = {
      types: {
        EIP712Domain: typesDomain,
        Message: typesConfigNoPrimaryType,
      },
      primaryType: "Message",
      domain: domainConfig,
      message: messageConfig
      };
    
		return [domainConfig, typesConfig, messageConfig, signMessageConfig];
	}, [chainId, deriwSubAccountPublicAddress, timeUnix, subAccount ]);
 
  const isNetWorkError = useMemo(()=>{
    if(active && account && currentChain){
      return !isSupportedChain(currentChain)
    }
    return false 
  },[currentChain, active, account])

  const getParentAccountRecursive = async (chainId, subAccount, attempts = 0) => {
    const parentAccount = await getAccountControl(chainId, subAccount);
    if (parentAccount === AddressZero && attempts < 10) {
      await new Promise(resolve => setTimeout(resolve, 5000)); // 等待5秒
      return getParentAccountRecursive(chainId, subAccount, attempts + 1);
    }
    return parentAccount;
  }

  const connectionCallBack = async () => {
    let parentAccount = await getParentAccountRecursive(chainId, subAccount);

    if(parentAccount !== AddressZero){
      setSubAccount({parentAccount, isSubConnected:true})
      ShowToast("Toast_Transaction", i18next.t("提示"), Info, [t("onchain transaction status", { action: t("创建链接"), status: t("success") })])
    }
    
    if(parentAccount !== AddressZero && parentAccount === account){
      if(isRememberMe){
        setHyperStorage(HYPER_USER_CONNECTION,sessionConnect, 'localStorage')
        setHyperStorage(HYPER_PERSIST_TRADING_CONNECTION, true, 'localStorage')
      } 
    }
  }

  const onSignTypedData = async () => {
    try {
      const signature = await walletClient.signTypedData({
        account,
        domain,
        types,
        primaryType: "Message",
        message
      });
      return { signature };
    }catch (error) {
			if (
				error &&
				error.message &&
				error.message.includes("User rejected the request.")
			) {
				const errorMessage = t("The user rejected the request.");
        ShowToast('Toast_Transaction', i18next.t("提示"), Warning, [t("onchain transaction status", { action: t("创建链接"), status: errorMessage })], { forever: false })
        console.error('Sign failed', errorMessage);
				return { signature:"" };
			}
      ShowToast('Toast_Transaction', i18next.t("提示"), Warning, [t("onchain transaction status", { action: t("创建链接"), status: t("failed") })], { forever: false })
      console.error('Sign failed', error);
      return { signature:"" };
		}
  }

  const onEstablishConnnect = async () => {
    if(isNetWorkError && active){
      switchNetwork(chainId, active)
      return;
    }
    
    onClose()
    ShowToast('Toast_Transaction', i18next.t("提示"), Info, [t("onchain transaction status", { action: t("创建链接"), status: t("Pending") }) + "..."], { forever: false })
    onSignTypedData().then(async ({ signature }) => {
      try {
        if(!signature) return;
        const signatureData = "0x" + Buffer.from(JSON.stringify(signMessageData)).toString('hex');
        // grantAccountControl参数
        let params = [
          signatureData, // signatureData
          signature  // signature
        ];
        let method = "grantAccountControl";

        const contract = new ethers.Contract(deriwSubAccountPublicAddress, DeriwSubAccountPublicABI, library.getSigner());
        callContract(chainId, contract, method, params, {
          pendingMsg: t("onchain transaction status", { action: t("创建链接"), status: t("Pending") }) + "...",
          // sentMsg: t("onchain transaction status", { action: t("创建链接"), status: t("submitted") }),
          // successMsg: t("onchain transaction status", { action: t("创建链接"), status: t("success") }),
          failMsg: t("onchain transaction status", { action: t("创建链接"), status: t("failed") }),
        }, connectionCallBack, deriwSubAccountPublicAddress, DeriwSubAccountPublicABI)
          .then(async (res) => {
            onClose()
          })
          .finally(() => {
          }).catch((e) => console.log("e", e))
  
      } catch (error) {
        ShowToast('Toast_Transaction', i18next.t("提示"), Warning, [t("onchain transaction status", { action: t("创建链接"), status: t("failed") })], { forever: false })
        console.error('Sign failed', error);
      }
    });
    
  }  

  return (
    <CModal2
      onCancel={onClose}
      down={true}
      visible={visible}
      width={400}
      title={t('创建链接')}
    >
        <div className="cancel_confirm_tx_secondary">
        <div className="confirm_tx_line pt-4 text-center">
          {t('此签名可零Gas发送，安全高效地打开去中心化交易通道，实现即时零Gas交易。')}
        </div>
        <div className="confirm_tx_line2 py-4">
          <div className="rememberme">{t('保持链接')} 
          </div>
          <Switch
            className="btn_switch"
            checked={isRememberMe} onChange={setRememberMe}
          />
        </div>

        <div className="confirm_tx_btn" onClick={
          () => {
            onEstablishConnnect()
          } 
          }>{isNetWorkError && active ? t('切换网络') : t('创建链接')}</div>
      </div> 

    </CModal2>
  );
};

export default ConnectionModal;
