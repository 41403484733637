import { useEffect, useState } from "react";
import { watchAccount } from "@wagmi/core";
import { useAccount } from "wagmi";
import { SELECTED_NETWORK_LOCAL_STORAGE_KEY } from "../../config/localStorage";
import { DEFAULT_CHAIN_ID, SUPPORTED_CHAIN_IDS, isSupportedChain } from "../../config/chains";
import { getRainbowKitConfig } from "../../lib/wallets/rainbowKitConfig";
import config from "../../config";

export const getChainId = () => {
  let chainId = DEFAULT_CHAIN_ID;
  const chainIdFromLocalStorage = localStorage.getItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY);
  if (chainIdFromLocalStorage) {
    chainId = parseInt(chainIdFromLocalStorage);
    if (!chainId) {
      // localstorage value is invalid
      localStorage.removeItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY);
    }
  }
  return chainId
}

export function useChainId() {
  let { chainId, address } = useAccount()
  if (!chainId) {
    chainId = getChainId()
  }

  if (!chainId || !isSupportedChain(chainId)) {
    chainId = DEFAULT_CHAIN_ID;
  }
 
  return { chainId };
}

