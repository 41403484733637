import i18next from "i18next";
import { getWalletConnectConnector } from "@rainbow-me/rainbowkit";
import { injected } from "@wagmi/core";
import identity from "lodash/identity";


export default function keplrWallet({ projectId, walletConnectParameters }) {
  const shouldUseWalletConnect = true;

  return {
    id: "keplr",
    name: "Keplr",
    iconUrl: async () => (await import("./keplrWallet.svg")).default,
    iconAccent: "#1E1E1E",
    iconBackground: "#1E1E1E",
    downloadUrls: {
      android: "https://play.google.com/store/apps/details?id=com.chainapsis.keplr&hl=en",
      ios: "https://apps.apple.com/us/app/keplr-wallet/id1567851089",
      chrome:"https://chromewebstore.google.com/detail/keplr/dmkamcknogkgcdfhhbddcghachkejeap?hl=en",
      mobile: "https://www.keplr.app/product/mobile-app",
      qrCode: "https://www.keplr.app/product/mobile-app",
    },
    qrCode: shouldUseWalletConnect
      ? {
          getUri: identity,
          instructions: {
            learnMoreUrl: "https://www.keplr.app/",
            steps: [
              {
                // Getters are used to defer the translation until the locale is set
                get description() {
                  return i18next.i18next.t`Log in to your Keplr app and tap [Wallets]. Go to [Web3].`;
                },
                step: "install",
                get title() {
                  return i18next.t`Open Keplr app`;
                },
              },
              {
                get description() {
                  return i18next.t`Tap [Create Wallet] to start using your Web3 Wallet.`;
                },
                step: "create",
                get title() {
                  return i18next.t`Create or Import a Wallet`;
                },
              },
              {
                get description() {
                  return i18next.t`After you scan, a connection prompt will appear for you to connect your wallet.`;
                },
                step: "scan",
                get title() {
                  return i18next.t`Scan the QR code`;
                },
              },
            ],
          },
        }
      : undefined,
    createConnector: (params) => {
      if (shouldUseWalletConnect) {
        return getWalletConnectConnector({ projectId, walletConnectParameters })(params);
      }

      return injected();
    },
  };
}
