import { useCallback, useEffect, useRef } from "react"
import networkConfig from "../config";

const CONNECT = "CONNECT"
const CONNMANAGER = "CONNMANAGER"

let listener = {}

/**
 * WebSocket Hook
 * @param {string} account - 用户账号
 * @returns {[React.MutableRefObject, Object]} - 返回socket实例引用和监听器对象
 */
const useSocket = (account) => {
    const socketInstance = useRef(null);
    const pingTimer = useRef(null);
    const reconnectCount = useRef(0);
    const MAX_RECONNECT_ATTEMPTS = 10;

    const initConnection = useCallback(() => {
        if (!account) return;

        // 清理之前的连接
        if (socketInstance.current) {
            socketInstance.current.close();
            clearInterval(pingTimer.current);
        }

        try {
            const ws = new WebSocket(`${networkConfig.wsUrl}`);
            socketInstance.current = ws;

            ws.onopen = () => {
                // 连接成功时重置重连计数
                reconnectCount.current = 0;
                // 发送连接认证
                const connectData = {
                    method: CONNECT,
                    body: account
                };
                ws.send(JSON.stringify(connectData));

                // 设置心跳检测
                pingTimer.current = setInterval(() => {
                    if (ws.readyState === WebSocket.OPEN) {
                        const pingData = {
                            module: CONNMANAGER,
                            method: "PING"
                        };
                        ws.send(JSON.stringify(pingData));
                    }
                }, 5000);
            };

            ws.onclose = () => {
                clearInterval(pingTimer.current);
                socketInstance.current = null;
                
                // 检查重连次数
                if (reconnectCount.current < MAX_RECONNECT_ATTEMPTS) {
                    reconnectCount.current += 1;
                    console.log(`WebSocket disconnected, attempting reconnection ${reconnectCount.current}/${MAX_RECONNECT_ATTEMPTS}`);
                    setTimeout(() => {
                        if (document.visibilityState === 'visible') {
                            initConnection();
                        }
                    }, 5000);
                } else {
                    console.log('Maximum reconnection attempts reached, connection aborted');
                }
            };

            ws.onmessage = (event) => {
                try {
                    const data = JSON.parse(event.data);
                    if (!data.method || !listener[data.method]) return;

                    listener[data.method].forEach(item => {
                        try {
                            item.callback(data);
                        } catch (err) {
                            console.error('Listener callback error:', err);
                        }
                    });
                } catch (err) {
                    console.error('Message parsing error:', err);
                }
            };

            ws.onerror = (error) => {
                console.error('WebSocket error:', error);
              
                // 清理现有连接和定时器
                clearInterval(pingTimer.current);
                if (socketInstance.current) {
                    socketInstance.current.close();
                    socketInstance.current = null;
                }

                // 检查重连次数
                if (reconnectCount.current < MAX_RECONNECT_ATTEMPTS) {
                    reconnectCount.current += 1;
                    console.log(`WebSocket error occurred, attempting reconnection ${reconnectCount.current}/${MAX_RECONNECT_ATTEMPTS}`);
                    setTimeout(() => {
                        if (document.visibilityState === 'visible') {
                            initConnection();
                        }
                    }, 5000);
                } else {
                    console.log('Maximum reconnection attempts reached, connection aborted');
                }
            };

        } catch (error) {
            console.error('Connection initialization error:', error);
            // 清理现有连接和定时器
            clearInterval(pingTimer.current);
            if (socketInstance.current) {
                socketInstance.current.close();
                socketInstance.current = null;
            }
            if (reconnectCount.current < MAX_RECONNECT_ATTEMPTS) {
                reconnectCount.current += 1;
                console.log(`Connection initialization failed, attempting reconnection ${reconnectCount.current}/${MAX_RECONNECT_ATTEMPTS}`);
                setTimeout(() => {
                    if (document.visibilityState === 'visible') {
                        initConnection();
                    }
                }, 5000);
            } else {
                console.log('Maximum reconnection attempts reached, connection aborted');
            }
        }
    }, [account]);

    // 初始化连接
    useEffect(() => {
        initConnection();
        return () => {
            if (socketInstance.current) {
                socketInstance.current.close();
            }
            clearInterval(pingTimer.current);
        };
    }, [account]);

    // 处理页面可见性变化
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible' && !socketInstance.current) {
                initConnection();
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, [initConnection]);

    return [socketInstance, listener];
};

const addListener = (id, method, callback) => {
    if (!listener[method]) {
        listener[method] = []
    }
    var index = listener[method].findIndex((x) => x.id == id);
    if (index > -1) {
        listener[method].splice(index, 1);
    }

    listener[method].push({ id: id, callback: callback })
    return listener[method];
}

const removeListener = (method, id) => {
    if (!listener[method] || listener[method].length == 0) {
        return
    }
    var index = listener[method].findIndex((x) => x.id == id);
    if (index > -1) {
        listener[method].splice(index, 1);
    }
    return listener[method];
}

export {
    useSocket, addListener, removeListener
};