import React from "react";
import "./index.scss";
import WalletDropdown from "../WalletDropdown";
import useWalletDeriw from "../../utils/wallets/useWalletDeriw";

const WalletConnect = (props) => {
  const { active, account } = useWalletDeriw()
  return (
    <div className="walletconnect">
      {
        props.content && active && account ? props.content : <WalletDropdown />
      }
    </div>
  );
};

export default WalletConnect;
