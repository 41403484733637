
import { extractError, NETWORK_CHANGED, NOT_ENOUGH_FUNDS, RPC_ERROR, SLIPPAGE, USER_DENIED } from "./transactionErrors";
import { getGasLimit, setGasPrice } from "./utils";
import { getChainName } from "../../config/chains";
import { getConnectedSubWallet, getUserAddress } from "../../utils/wallets";
import { calculateGasMargin } from "../../utils/calculateGasMargin";
import { Info, ShowToast, Warning } from "../../components/Toast";
import i18next from "i18next";
import config from "../../config";
import { ethers } from "ethers";
import { isEmpty } from "lodash";

const ToastId = "Toast_Transaction"
export async function callContract(
  chainId,
  contract,
  method,
  params,
  opts,
  callBack,
  contractAddress,
  abi,
  subAccount = true
) {
  if (subAccount) {
    if (opts.pendingMsg) {
      try {
        if (!opts) {
          opts = {};
        }
        ShowToast(ToastId, i18next.t("提示"), Info, [opts.pendingMsg], { forever: true })
        const account = getUserAddress();
        const connectedSubWallet = getConnectedSubWallet(account);

        if(isEmpty(connectedSubWallet)){
          ShowToast(ToastId, i18next.t("提示"), Warning, [opts.failMsg], { forever: false })
          return 
        }

        // 使用 ethers.js 创建 provider
        const provider = new ethers.providers.JsonRpcProvider(config.ethRpc);
        
        // 使用私钥创建 wallet
        const wallet = new ethers.Wallet(connectedSubWallet?.privateKey, provider);
        
        // 创建合约实例
        const rContract = new ethers.Contract(
          contractAddress,
          abi,
          wallet
        );

        // const gasLimit = await rContract.methods[method](...params).estimateGas({from: wallet.address, value: '0x0' })
        const gasLimit = await rContract.estimateGas[method](...params);
        let limit = calculateGasMargin(gasLimit) 
        if (gasLimit.lt(ethers.BigNumber.from('22000'))) {
          limit = ethers.BigNumber.from('22000');
        }
        // 获取当前 nonce
        // const nonce = await provider.getTransactionCount(wallet.address, 'pending');
        // 发送交易
        const tx = await rContract[method](...params, {
          from: wallet.address,
          gasLimit: limit,
          value: 0,
          maxFeePerGas: 0,
          maxPriorityFeePerGas: 0
        });
        // 等待交易完成
        const receipt = await tx.wait(1);
        if(receipt?.status === 1){
          if(opts.successMsg) {
            ShowToast(ToastId, i18next.t("提示"), Info, [opts.successMsg])
          }
          if (callBack) callBack()
        } else {
          ShowToast(ToastId, i18next.t("提示"), Warning, [opts.failMsg], { forever: false })
        }
        return receipt
      } catch(e) {
        console.log("callContract ~ e:", e)
        ShowToast(ToastId, i18next.t("提示"), Warning, [opts.failMsg], { forever: false })
        return
      }
    }
  } else {

    try {
      if (!Array.isArray(params) && typeof params === "object" && opts === undefined) {
        opts = params;
        params = [];
      }
  
      if (!opts) {
        opts = {};
      }
      const txnOpts = {};
  
      // if (opts.value) {
      //   txnOpts.value = opts.value;
      // }
      if (opts.pendingMsg) {
        ShowToast(ToastId, i18next.t("提示"), Info, [opts.pendingMsg], { forever: true })
      }
      txnOpts.gasLimit = opts.gasLimit ? opts.gasLimit : await getGasLimit(contract, method, params, opts.value);
  
      await setGasPrice(txnOpts, contract.provider, chainId, opts);
      const res = await contract[method](...params, txnOpts);
  
      // const rContract = new web3.eth.Contract(
      //   ReferralStorageABI,
      //   '0x197d346c774454f1c55C64133Dc2Fb3c710D1db0'
      // );
  
      // const codeOwnersRes  = await rContract.methods.registerCode(codeParams).send({ from: wallet.address, value: '0x0', gasLimit: 221240 })
  
      const sentMsg = opts.sentMsg || `Transaction sent.`;
  
      ShowToast(ToastId, i18next.t("提示"), Info, [sentMsg], { forever: true })
  
      // 
      // if (opts.setPendingTxns) {
      //   const message = opts.hideSuccessMsg ? undefined : opts.successMsg || `Transaction completed!`;
      //   const pendingTxn = {
      //     hash: res.hash,
      //     message,
      //   };
      //   opts.setPendingTxns((pendingTxns) => [...pendingTxns, pendingTxn]);
      //   // ShowToast(ToastId, opts.successMsg )
      // }
      if (callBack) {
        callBack()
      }
      await res.wait();
      ShowToast(ToastId, i18next.t("提示"), Info, [opts.successMsg])
      return res;
    } catch (e) {
      let failMsg;
  
      let autoCloseToast = 5000;
  
      const [message, type, errorData] = extractError(e);
      switch (type) {
        case NOT_ENOUGH_FUNDS:
          failMsg = "There is not enough ETH in your account on Arbitrum to send this transaction."
          // failMsg = (
          //   <>
          //     There is not enough ETH in your account on Arbitrum to send this transaction.
          //     <br />
          //     <br />
          //     {/* <ExternalLink href="https://arbitrum.io/bridge-tutorial/">Bridge ETH to Arbitrum</ExternalLink> */}
          //   </>
          // );
          break;
        case NETWORK_CHANGED:
          failMsg = i18next.t('Your wallet is not connected to x', {x: getChainName(chainId)})
          // failMsg = (
          //   <>
          //     <div>Your wallet is not connected to {getChainName(chainId)}.</div>
          //     <br />
          //     <div className="clickable underline" onClick={() => switchNetwork(chainId, true)}>
          //       Switch to {getChainName(chainId)}
          //     </div>
          //   </>
          // );
          break;
        case USER_DENIED:
          failMsg = `Transaction was cancelled.`;
          break;
        case SLIPPAGE:
          failMsg = `The mark price has changed, consider increasing your Allowed Slippage by clicking on the "..." icon next to your address.`;
          break;
        case RPC_ERROR:
          failMsg = `Transaction failed due to RPC error.`;
          // autoCloseToast = false;
  
          // const originalError = errorData?.error?.message || errorData?.message || message;
  
          // failMsg = (
          //   <div>
  
          //     Transaction failed due to RPC error.
          //     <br />
          //     <br />
          //     Please try changing the RPC url in your wallet settings.{" "}
          //     {/* <ExternalLink href="https://gmxio.gitbook.io/gmx/trading#backup-rpc-urls">More info</ExternalLink> */}
  
          //     <br />
          //     {/* {originalError && <ToastifyDebug>{originalError}</ToastifyDebug>} */}
          //   </div>
          // );
          break;
        default:
          autoCloseToast = false;
  
          failMsg = (
            <div>
              {opts.failMsg || `Transaction failed`}
              <br />
              {/* {message && <ToastifyDebug>{message}</ToastifyDebug>} */}
            </div>
          );
      }
      ShowToast(ToastId, i18next.t("提示"), Warning, [opts.failMsg], { forever: false })
      console.error("failMsg", failMsg)
      // helperToast.error(failMsg, { autoClose: autoCloseToast });
      throw e;
    }
  }
}
