import React from 'react';
import './loading.scss';
import KLineLoadingDark from '../../assets/images/k_line_loading.gif';
import KLineLoadingLight from '../../assets/images/k_line_loading.gif';
import useModeStore from "../../store/useModeStore";
import { useShallow } from 'zustand/react/shallow';
/**
 * 通用加载组件
 * @returns {JSX.Element} Loading 组件
 */
const Loading = () => {
  const { mode } = useModeStore(useShallow((state) => ({ mode: state.mode })))

  return (
    <div className="transaction_k_loading">
      <img src={mode == 'dark' ? KLineLoadingDark : KLineLoadingLight} alt="loading" />
    </div>
  );
};

export default Loading;