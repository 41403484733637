import { ethers } from "ethers";
import { useMemo } from "react";
import { useConnectorClient } from "wagmi";

export function clientToSigner(client) {
  const { account, chain, transport } = client;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };
  const provider = new ethers.providers.Web3Provider(transport, network);

  return provider.getSigner();
}

/** Hook to convert a Viem Client to an ethers.js Signer. */
export function useEthersSigner() {
  const { data: client } = useConnectorClient();

  return useMemo(() => {
    if (!client?.account) {
      return undefined;
    }

    try {
      return clientToSigner(client);
    } catch (error) {
      return undefined;
    }
  }, [client]);
}
