import { useEffect, useState } from "react";
import useWallet from "../../lib/wallets/useWallet";
import { ethers } from "ethers";
import useSubAccountStore from "../../store/useSubAccountStore";
import { useShallow } from 'zustand/react/shallow';
import config from "../../config";
import useHyperConnection from "../../hooks/useHyperConnection";

/**
 * @typedef {Object} WalletDeriw
 * @property {string} subAccount - 子账户地址
 * @property {Object} library - Web3 库实例
 * @property {Function} library.getSigner - 获取签名者实例的方法
 */

/**
 * @description 扩展钱包功能的Hook，在基础钱包功能上添加子账户和自定义library支持
 * @returns {WalletDeriw} 扩展后的钱包对象
 */
export default function useWalletDeriw() {
  // 基础钱包实例
  const web3Wallet = useWallet();
  
  // 获取子账户连接状态
  const { hasSessionConnect, connectedSubWallet } = useHyperConnection();
  const { isSubConnected } = useSubAccountStore(
    useShallow((state) => ({ isSubConnected: state.isSubConnected})),
  )
  // 存储扩展后的钱包状态
  const [walletDeriw, setWalletDeriw] = useState({});

  useEffect(() => {
    // 创建扩展钱包对象
    const createExtendedWallet = () => {
      // 基础配置
      const baseWallet = { ...web3Wallet };

      // 子账户已连接
      if (isSubConnected || hasSessionConnect) {
        const web3Library = {
          getSigner: () => {
            const provider = new ethers.providers.StaticJsonRpcProvider(config.ethRpc);
            return provider.getSigner(web3Wallet.account);
          }
        };

        return {
          ...baseWallet,
          subAccount: connectedSubWallet?.agentAddress,
          library: web3Library,
        };
      }

      // 子账户未连接
      return {
        ...baseWallet,
        subAccount: '',
      };
    };
    const extendedWallet = createExtendedWallet()
    setWalletDeriw(extendedWallet);
  }, [
    web3Wallet?.chainId,
    web3Wallet?.active,
    web3Wallet?.account,
    web3Wallet?.walletClient,
    hasSessionConnect,
    isSubConnected,
    connectedSubWallet?.agentAddress 
  ]);

  return walletDeriw;
}

