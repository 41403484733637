
import { useEffect, useState } from "react";
import { DERIWTESTNET, MAINNET, TESTNET, DERIWDEVNET, DERIWPRENET } from "./chains";
import httpApi from "../api";
import config from "../config";


export var TOKENS = []
export var TOKENS_MAP = {};
export var TOKENS_BY_SYMBOL_MAP = {};

export function useLoadTokens() {

    const [tokens, setTokens] = useState([])
    const [tokensMap, setTokensMap] = useState({})
    const [tokensBySymbolMap, setTokensBySymbolMap] = useState({})

    const getToken = async () => {
        if (TOKENS.length > 0) {
            return
        }
        const res = await httpApi.getSupportCoins()
        TOKENS = (res && res.data && res.data.list ? res.data.list : []).map((token) => {
            return {
                ...token,
                symbol: token.name,
                decimals: token.decimals,
                dec: token.decimals,
                imageUrl: `${config.tokenAssetBaseUrl}${token.name}.png`,
                isShortable: token.is_shortable,
                isWrapped: token.is_wrapped,
                isStable: token.is_stable,
                from: token.from
            }
        })

        for (let j = 0; j < CHAIN_IDS.length; j++) {
            const chainId = CHAIN_IDS[j];
            TOKENS_MAP[chainId] = {};
            TOKENS_BY_SYMBOL_MAP[chainId] = {};
            let tokens = TOKENS

            for (let i = 0; i < tokens.length; i++) {
                const token = tokens[i];
                TOKENS_MAP[chainId][token.address] = token;
                TOKENS_BY_SYMBOL_MAP[chainId][token.symbol] = token;
            }
        }

        for (const chainId of CHAIN_IDS) {
            for (const token of TOKENS) {
                if (token.isWrapped) {
                    WRAPPED_TOKENS_MAP[chainId] = token;
                } else if (token.isNative) {
                    NATIVE_TOKENS_MAP[chainId] = token;
                }
            }
        }
        setTokens(TOKENS)
        setTokensMap(TOKENS_MAP)
        setTokensBySymbolMap(TOKENS_BY_SYMBOL_MAP)
    }
    useEffect(() => {
        getToken()
    }, [])

    return {
        tokens, tokensMap, tokensBySymbolMap
    }
}



// export var TOKENS = {
//     [MAINNET]: [],
//     [TESTNET]: [
//         {
//             name: "Bitcoin (BTCB)",
//             symbol: 'WBTC',
//             decimals: 8,
//             address: '0xE98B3317bF7B95f9840A702cefE6E8ab6D7fc82C',
//             dec: 8,
//             isShortable: true,
//             imageUrl: "https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295",
//         },
//         {
//             name: "Ethereum",
//             symbol: "WETH",
//             address: "0x5B3D0D6A54D6cD2934C44bd0fe76384780fc0678",
//             decimals: 18,
//             dec: 18,
//             imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
//         },
//         // {
//         //     name: "BNB",
//         //     symbol: "BNB",
//         //     address: '0x0000000000000000000000000000000000000000',
//         //     decimals: 18,
//         //     dec: 18,
//         //     isWrapped: true,
//         //     imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
//         // },
//         {
//             name: 'USDT',
//             symbol: 'USDT',
//             address: '0x21007fbfe34972C9B968a4AB1AB94AfdE9170244',
//             decimals: 6,
//             dec: 6,
//             isStable: true,
//             imageUrl: "https://assets.coingecko.com/coins/images/325/small/Tether-logo.png",
//             from: true
//         },
//         {
//             name: 'UNI',
//             symbol: 'UNI',
//             address: '0x2Cb2C959516DBC8Bf3dfeF0b228692e9a77405D8',
//             decimals: 18,
//             dec: 18,
//             isStable: false,
//             isShortable: true,
//             imageUrl: "https://assets.coingecko.com/coins/images/12504/thumb/uniswap-uni.png?1600306604",
//         },
//         {
//             name: 'LINK',
//             symbol: 'LINK',
//             address: '0xcEB9be9020CDCBB50957562A244823615127eBE3',
//             decimals: 18,
//             dec: 18,
//             isStable: false,
//             isShortable: true,
//             imageUrl: "https://assets.coingecko.com/coins/images/877/thumb/chainlink-new-logo.png?1547034700",
//         },
//         // {
//         //     name: "USD Gambit",
//         //     symbol: "USDG",
//         //     decimals: 18,
//         //     address: "0x3AC521c0640C46D907526D71C41B44C86e53d656",
//         //     isUsdg: true,
//         //     imageUrl: "https://assets.coingecko.com/coins/images/15886/small/usdg-02.png",
//         // },
//     ],
//     [DERIWTESTNET]: [
//         {
//             name: "Bitcoin (BTCB)",
//             symbol: 'WBTC',
//             decimals: 8,
//             address: '0xdBE75CB0f179169638Db1046D81eED9F6B68d5d4',
//             dec: 8,
//             isShortable: true,
//             imageUrl: "https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295",
//         },
//         {
//             name: "Ethereum",
//             symbol: "WETH",
//             address: "0xFCcF5bd58d78ABb2629bD96cb81C150c7121eDD6",
//             decimals: 18,
//             dec: 18,
//             imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
//         },
//         {
//             name: "ETH",
//             symbol: "ETH",
//             address: '0x0000000000000000000000000000000000000000',
//             decimals: 18,
//             dec: 18,
//             isWrapped: true,
//             imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
//         },
//         {
//             name: 'USDT',
//             symbol: 'USDT',
//             address: '0x60c03C6cA6eB207BD2Cb9d8499C4fE95Ad29D4E1',
//             decimals: 6,
//             dec: 6,
//             isStable: true,
//             imageUrl: "https://assets.coingecko.com/coins/images/325/small/Tether-logo.png",
//             from: true
//         },
//         {
//             name: 'UNI',
//             symbol: 'UNI',
//             address: '0x71DB70d3e8012dab7326220cEa80434aBEcA0467',
//             decimals: 18,
//             dec: 18,
//             isStable: false,
//             isShortable: true,
//             imageUrl: "https://assets.coingecko.com/coins/images/12504/thumb/uniswap-uni.png?1600306604",
//         },
//         {
//             name: 'LINK',
//             symbol: 'LINK',
//             address: '0xF91919279E393256Fa764739d6974045c19a4E01',
//             decimals: 18,
//             dec: 18,
//             isStable: false,
//             isShortable: true,
//             imageUrl: "https://assets.coingecko.com/coins/images/877/thumb/chainlink-new-logo.png?1547034700",
//         },
//     ],
//     [DERIWTESTNETSECOND]: [
//         {
//             name: "Bitcoin (BTCB)",
//             symbol: 'WBTC',
//             decimals: 8,
//             address: '0xb4845Ce2d018ceFE0dDc690aA7Fb4Fa714A638eB',
//             dec: 8,
//             isShortable: true,
//             imageUrl: "https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295",
//         },
//         {
//             name: "Ethereum",
//             symbol: "WETH",
//             address: "0x5190Df68823075Ef1ce006C0D6754B690e3619CA",
//             decimals: 18,
//             dec: 18,
//             imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
//         },
//         {
//             name: "ETH",
//             symbol: "ETH",
//             address: '0x0000000000000000000000000000000000000000',
//             decimals: 18,
//             dec: 18,
//             isWrapped: true,
//             imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
//         },
//         {
//             name: 'USDT',
//             symbol: 'USDT',
//             address: '0x694c06B9fC7f8aab822deF6cde91514370ee56Cb',
//             decimals: 18,
//             dec: 18,
//             isStable: true,
//             imageUrl: "https://assets.coingecko.com/coins/images/325/small/Tether-logo.png",
//             from: true
//         },
//         {
//             name: 'UNI',
//             symbol: 'UNI',
//             address: '0xf54D4ad77b4CD2C3f579949174e533a0fb62668c',
//             decimals: 18,
//             dec: 18,
//             isStable: false,
//             isShortable: true,
//             imageUrl: "https://assets.coingecko.com/coins/images/12504/thumb/uniswap-uni.png?1600306604",
//         },
//         {
//             name: 'LINK',
//             symbol: 'LINK',
//             address: '0x5eCA00DBCdb7CeE8A39524C87a23A40509AA1B3E',
//             decimals: 18,
//             dec: 18,
//             isStable: false,
//             isShortable: true,
//             imageUrl: "https://assets.coingecko.com/coins/images/877/thumb/chainlink-new-logo.png?1547034700",
//         },
//     ],
//     /*[
//         {
//             name: "Bitcoin (BTCB)",
//             symbol: "BTC",
//             decimals: 8,
//             address: "0xb19C12715134bee7c4b1Ca593ee9E430dABe7b56",
//             imageUrl: "https://assets.coingecko.com/coins/images/26115/thumb/btcb.png?1655921693",
//         },
//         {
//             name: "Ethereum",
//             symbol: "ETH",
//             decimals: 18,
//             address: "0x1958f7C067226c7C8Ac310Dc994D0cebAbfb2B02",
//             imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
//         },
//         {
//             name: "Binance Coin",
//             symbol: "BNB",
//             decimals: 18,
//             address: ethers.constants.AddressZero,
//             isNative: true,
//             imageUrl: "https://assets.coingecko.com/coins/images/825/small/binance-coin-logo.png",
//         },
//         {
//             name: "Wrapped Binance Coin",
//             symbol: "WBNB",
//             decimals: 18,
//             address: "0x612777Eea37a44F7a95E3B101C39e1E2695fa6C2",
//             isWrapped: true,
//             baseSymbol: "BNB",
//             imageUrl: "https://assets.coingecko.com/coins/images/825/small/binance-coin-logo.png",
//         },
//         {
//             name: "USD Gambit",
//             symbol: "USDG",
//             decimals: 18,
//             address: getContract(TESTNET, "USDG"),
//             isUsdg: true,
//             imageUrl: "https://assets.coingecko.com/coins/images/15886/small/usdg-02.png",
//         },
//         {
//             name: "Binance USD",
//             symbol: "BUSD",
//             decimals: 18,
//             address: "0x3F223C4E5ac67099CB695834b20cCd5E5D5AA9Ef",
//             isStable: true,
//             imageUrl: "https://assets.coingecko.com/coins/images/9576/small/BUSD.png",
//         },
//     ]*/
// };


export const PLATFORM_TOKENS = {
    [MAINNET]: {
    },
    [TESTNET]: {
    },
};

export const ICONLINKS = {
    [MAINNET]: {},
    [TESTNET]: {}
};



export const WRAPPED_TOKENS_MAP = {};
export const NATIVE_TOKENS_MAP = {};

const CHAIN_IDS = [MAINNET, TESTNET, DERIWTESTNET, DERIWDEVNET, DERIWPRENET];


export function getWrappedToken(chainId) {
    return WRAPPED_TOKENS_MAP[chainId];
}

export function getNativeToken(chainId) {
    return NATIVE_TOKENS_MAP[chainId];
}

export function getTokens(chainId) {
    return TOKENS;
}

export function getFromTokens(chainId) {
    return TOKENS.filter(({ from }) => from == true);
}

export function isValidToken(chainId, address) {
    if (!TOKENS_MAP[chainId]) {
        throw new Error(`Incorrect chainId ${chainId}`);
    }
    return address in TOKENS_MAP[chainId];
}

export function getToken(chainId, address) {
    if (!TOKENS_MAP[chainId]) {
        return
        // throw new Error(`Incorrect chainId ${chainId}`);
    }
    if (!TOKENS_MAP[chainId][address]) {
        return
        // throw new Error(`Incorrect address "${address}" for chainId ${chainId}`);
    }
    return TOKENS_MAP[chainId][address];
}

export function getTokenBySymbol(chainId, symbol) {
    const token = TOKENS_BY_SYMBOL_MAP?.[chainId]?.[symbol];
    if (!token) {
        return {}
        // throw new Error(`Incorrect symbol "${symbol}" for chainId ${chainId}`);
    }
    return token;
}

export function getWhitelistedTokens(chainId) {
    return TOKENS.filter((token) => token.symbol !== "USDG");
}

export function getVisibleTokens(chainId) {
    return getWhitelistedTokens(chainId).filter((token) => !token.isWrapped && !token.isTempHidden);
}

export function getNormalizedTokenSymbol(tokenSymbol) {
    if (["WBTC", "WETH", "WAVAX"].includes(tokenSymbol)) {
        return tokenSymbol.substr(1);
    } else if (tokenSymbol === "BTC.b") {
        return "BTC";
    }
    return tokenSymbol;
}
